<template>
    <CTabla @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
        <template slot="header">
            <th class="td-sm"></th>
            <th>Mensaje</th>
            <th class="text-center">Agregar</th>
        </template>

        <template slot="body">
            <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                <td class="td-sm"></td>
                <td>
                    <div class="mensje">
                        <div v-html="lista.Texto"></div>
                    </div>
                </td>
                <td class="text-center">
                    <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pEmitSeccion="ConfigList.EmitSeccion" :segurity="segurity">
                        <template slot="btnaccion">
                            <button type="button" v-if="lista.Visible" v-b-tooltip.hover.Top title="Agregar" class="btn btn-primary btn-icon" @click="AsignarMensaje(lista)">
                                <i class="fas fa-plus"></i>
                            </button>
                            <span v-else class="badge bg-success">
                                Asignado
                            </span>
                        </template>
                    </CBtnAccion>
                </td>
            </tr>
            <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="3"></CSinRegistro>
        </template>
    </CTabla>
</template>

<script>

const EmitEjecuta = 'seccionMensajes';

export default {
    name: 'ListMensajes',
    props: ['poBtnSave'],
    data() {
        return{
            ConfigList:{
                Title: 'Mensajes Predeterminados',
                ShowLoader: false,
                BtnNewShow: false,
                ShowTitleFirst: false,
                ShowFiltros: false,
                ShowPaginador: false,
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            Emit: this.poBtnSave.EmitSeccion,
            errorvalidacion: [],
            ListaArrayRows: [],
            ListaHeader: [],
            objEquipos: {},
            segurity: {},
            obj: {},
        }
    },
    methods:{
        async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('mensajeCorreo', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data;
                /* this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total; */

                this.ListaArrayRows.forEach((item, index) => {
                    item.Visible = true
                    if(item.IdMensajeCorreo == this.objEquipos.IdMensajeCorreo){
                        item.Visible = false;
                    }
                });
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        AsignarMensaje(item)
        {
            this.bus.$emit('pAsignarMensaje',item);
            this.bus.$emit('CloseModal_'+this.Emit);
        },
    },
    created(){
        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(obj)=>
        {
            //this.objEquipos = obj
            this.Lista();
        });
    },
}

</script>
<style>
.mensje{
    max-height: 120px !important;
    overflow-y: auto;
}
</style>