<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item1">Nombre(s)</label>
                                    <input type="text" name="item1" id="item1" class="form-control" placeholder="Nombre(s)" v-model="objUsuarioSuc.Nombre">
                                    <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item2">Apellido Paterno</label>
                                    <input type="text" name="item2" id="item2" class="form-control" placeholder="Apellido Paterno" v-model="objUsuarioSuc.ApellidoPat">
                                    <CValidation v-if="this.errorvalidacion.ApellidoPat" :Mensaje="'*'+errorvalidacion.ApellidoPat[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item3">Apellido Materno</label>
                                    <input type="text" name="item3" id="item3" class="form-control" placeholder="Apellido Materno" v-model="objUsuarioSuc.ApellidoMat">
                                    <CValidation v-if="this.errorvalidacion.ApellidoMat" :Mensaje="'*'+errorvalidacion.ApellidoMat[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item20">Genero</label>
                                    <select v-model="objUsuarioSuc.Genero" name="item20" id="item20" class="form-control form-select">
                                        <option value="0">--Seleccionar--</option>
                                        <option  v-for="(item, index) in ListaGeneros" :key="index" :value="item.label" >{{item.label}}</option>
                                    </select>
                                    <CValidation v-if="this.errorvalidacion.Genero" :Mensaje="'*'+errorvalidacion.Genero[0]"/>
                                </div>

                            </div>

                            <div class="form-group form-row">

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item20">Perfil</label>
                                    <select @change="ListaPerfiles" v-model="objUsuarioSuc.IdPerfil" name="item20" id="item20" class="form-control form-select">
                                        <option value="0">--Seleccionar--</option>
                                        <option  v-for="(item, index) in ListaDePerfil" :key="index" :value="item.IdPerfil" >{{item.Nombre}}</option>
                                    </select>
                                    <CValidation v-if="this.errorvalidacion.IdPerfil" :Mensaje="'*'+errorvalidacion.IdPerfil[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item3">Correo</label>
                                    <input type="text" name="item3" id="item4" class="form-control" placeholder="Correo Electronico" v-model="objUsuarioSuc.Correo">
                                    <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*'+errorvalidacion.Correo[0]"/>
                                </div>

                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <label for="item3">Telefono</label>
                                    <input type="text" name="item3" id="item4" class="form-control" placeholder="Telefono" v-model="objUsuarioSuc.Telefono">
                                    <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*'+errorvalidacion.Telefono[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row" v-if="objUsuarioSuc.IdUsuario == 0">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
                                    <label for="item16">Usuario</label>
                                    <input type="text" name="item16" id="item16" class="form-control" placeholder="Nombre del Usuario" v-model="objUsuarioSuc.username" >
                                    <CValidation v-if="this.errorvalidacion.username" :Mensaje="'*'+errorvalidacion.username[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
                                    <label for="item18">Contraseña</label>
                                    <input v-model="objUsuarioSuc.password" :type="type1" class="form-control" placeholder="Contraseña" name="item18" id="item18">
                                    <button v-if="objUsuarioSuc.password !== ''" @click="ToggleShow" class="button btn-password-formulario" type="button" id="button-addon2">
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>
                                </div>

                                <div class="col-12 col-sm-12 col-md-3 col-lg-4 col-xl-4">
                                    <label for="item19">Confirmar Contraseña</label>
                                    <input v-model="objUsuarioSuc.password_confirmation" :type="type2" class="form-control" placeholder="Confirmar Contraseña" name="item19" id="item19">
                                    <button v-if="objUsuarioSuc.password_confirmation !== ''" @click="ToggleShowConfirm" class="button btn-password-formulario" type="button" id="button-addon2">
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    import staticComboBox from "../../../helpers/StaticComboBox";

    export default {
        name:       "FormUusuarioSucursal",
        props:      ['poBtnSave'],
        components: { },
        data() {
            return {  
                type1:               'password', 
                type2:               'password',         
                showPassword:        false,
                showPasswordConfirm: false,
                password:            null,
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad: false,
                },
                objUsuarioSuc:{
                    IdUsuario:             0,
                    IdEmpresa:             0,
                    IdSucursal:            0,
                    IdPuesto:              0,
                    IdPerfil:              0,
                    IdPais:                1,
                    IdEstado:              0,
                    IdMunicipio:           0,
                    Genero:                0,
                    username:              '',
                    password:              '',
                    Nombre:                '',
                    ApellidoPat:           '',
                    ApellidoMat:           '',
                    NombreCompleto:        '',
                    Correo:                '',
                    password_confirmation: '',
                },
                errorvalidacion: [],
                Emit: this.poBtnSave.EmitSeccion,
                ObjSucursal: {},
                ListaDePerfil:[],
                ListaGeneros: staticComboBox.Genero,
            }
        },
        methods :
        {
            async Guardar()
            {
                this.errorvalidacion = [];
                this.poBtnSave.toast = 0;
                this.poBtnSave.DisableBtn = true;

                if(this.objUsuarioSuc.IdUsuario == 0)
                {
                    this.$http.post(
                        'usuariossucursal',
                        this.objUsuarioSuc
                    ).then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
                }
                else
                {
                    this.$http.put(
                        'usuariossucursal/'+this.objUsuarioSuc.IdUsuario,
                        this.objUsuarioSuc
                    ).then((res)=>{
                        this.EjecutaConExito(res);
                    }).catch(err=>{
                        this.EjecutaConError(err);
                    });
                }
            },
            EjecutaConExito(res)
            {
                this.poBtnSave.DisableBtn = false;
                this.poBtnSave.toast = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err)
            {
                this.poBtnSave.DisableBtn = false;

                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                }
                else{
                    this.$toast.error(err.response.data.message);
                }
            },
            Recuperar()
            {
                this.$http.get(
                    "usuariossucursal/"+this.objUsuarioSuc.IdUsuario
                ).then( (res) => {
                    this.objUsuarioSuc = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar()
            {
                
                this.ListaGeneros  = staticComboBox.Genero,
                this.objUsuarioSuc = {
                    IdUsuario:             0,
                    IdEmpresa:             this.ObjSucursal.IdEmpresa,
                    IdSucursal:            this.ObjSucursal.IdSucursal,
                    IdPerfil:              0,
                    IdPuesto:              0,
                    IdPais:                1,
                    IdEstado:              this.ObjSucursal.IdEstado,
                    IdMunicipio:           this.ObjSucursal.IdMunicipio,
                    Genero:                0,
                    username:              '',
                    password:              '',
                    Nombre:                '',
                    ApellidoPat:           '',
                    ApellidoMat:           '',
                    NombreCompleto:        '',
                    Correo:                '',
                    password_confirmation: '',
                };
                this.errorvalidacion = [];
            },
            async ListaPerfiles(){
                await this.$http.get('perfiles', {params:{}}).then( (res) => {
                    this.ListaDePerfil = res.data.data.perfiles.data;
                });
            },
            ToggleShow() {

                if (this.showPassword = !this.showPassword) {                    
                    this.type1 = 'text'
                } else {
                    this.type1 = 'password'
                }

            },
            ToggleShowConfirm() {            
        
                if (this.showPasswordConfirm = !this.showPasswordConfirm) {                    
                    this.type2 = 'text'
                } else {
                    this.type2 = 'password'
                }

            }
        },
        created() {
            this.ListaPerfiles();
            this.ObjSucursal = JSON.parse(sessionStorage.getItem('oSucursal'));

            this.poBtnSave.toast = 0;

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(Id)=>
            {
                this.ConfigLoad.ShowLoader = true;
                this.poBtnSave.DisableBtn = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=>
                {
                    this.Guardar();
                });
                this.Limpiar();

                if(Id!='')
                {
                    this.objUsuarioSuc.IdUsuario = Id;
                    this.Recuperar();
                }
                else
                {
                    this.ConfigLoad.ShowLoader = false;
                }
            });
        },
    }

</script>
