<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
                <th>Folio</th>
              
                <th>Fecha</th>
                <th class="text-center">Cotizacion</th>
                <th class="text-center">Orden de Trabajo</th>
                <th>Descripción</th>
                 
                <th class="text-center">Acciones</th>
			</template>

			<template slot="body">
				<tr v-for="(lista,index) in ListaArrayRows" :key="index">
					<td>RPA-{{ lista.IdAnomaliaReporte }}</td>
					
                    <td>{{$getCleanDate(lista.FechaAnomalia, false)}}</td>
                    <td class="text-center">{{lista.FolioCotizacion}}</td>
                    <td class="text-center">{{lista.FolioOrdenTrabajo}}</td>
                    <td>{{$limitCharacters(lista.DescripcionGeneral,80)}}</td>
                    
					<td class="text-center">
						<CBtnAccion  :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="false" :pId="lista.IdAnomaliaReporte" :pEmitSeccion="ConfigList.EmitSeccion" :pGoRoute="ConfigList.GoRoute" :segurity="segurity">
							<template slot="btnaccion">
                                <button type="button" @click="verAnomalia(lista)" v-b-tooltip.hover.Top title="Ver Resumen Anomalia" class="btn btn-info btn-icon ml-1">
                                    <i class="fas fa-eye"></i>
                                </button>

                                <button type="button" @click="verLinkCliente(lista)" v-b-tooltip.hover.Top title="Obtener link para compartir" class="btn btn-primary btn-icon ml-1">
                                    <i class="fas fa-link"></i>
                                </button>

                            </template>
						</CBtnAccion>     
					</td>
				</tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="6"></CSinRegistros>
			</template>
		</CList>

	</div>
</template>
<script>

import moment from 'moment';
import Configs from '@/helpers/VarConfig.js';
import CSinRegistros from '../../components/CSinRegistros.vue';
const EmitEjecuta = 'seccionReporteanomalia';

export default {
    name: 'ListaReporteanomalia',
    components: {
        CSinRegistros,
    },
    data() {
        return {
            ConfigList:{
                Title: 'Listado',
                IsModal: false,
                /*ShowYear: true,*/
                ShowLoader: true,
                EmitSeccion: EmitEjecuta,
                ShowSearch: true,
                ShowEntradas: true,
                /*BtnReturnShow: true,
                TitleFirst: 'Inicio'*/
                GoRoute: 'formanomalias',
                greenHeader:true
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 25,
                TotalItem: 0,
                Placeholder: 'Buscar..',
                /*Anio: moment(new Date()).format('YYYY'),*/
            },
            segurity: {},
            obj: {},
            ListaArrayRows: [],               
            ListaHeader: [],
            urlSitio: '',
        }
    },
    methods: {
		async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("reportesanomalias",{
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,
                }
            }).then((res) => {
                this.Filtro.Pagina      = res.data.data.current_page;
                this.Filtro.TotalItem   = res.data.data.total;
                this.ListaArrayRows     = res.data.data.data;
                this.urlSitio           = res.data.Ruta;
                //this.segurity           = res.data.segurity;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        Eliminar(Id)
        {
            this.$swal(Configs.configEliminar).then((result) =>
            {
                if(result.value)
                {
                    this.$http.delete('reportesanomalias/'+Id
                    ).then((res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    });
                } 
            });
        },

        verAnomalia(item){
            this.$router.push({name:"formanomalias",params:{Id:item.IdAnomaliaReporte}})
        },

        async verLinkCliente(item){
            
            //let link = 'http://localhost:8080/viewanomalia?feat='+item.ClienteKey;
            let link    = 'https://help.secont.mx/viewanomalia?feat='+item.ClienteKey;

            this.$swal.fire({
                title: "Link Generado",
                text: link,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Copiar link",
                denyButtonText: `Cerrar`

                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */

                if (result.isConfirmed) {
                    this.copiarContenido(link)
                    
                } else if (result.isDenied) {
                   
                }
            });

        },

        async copiarContenido(linkCliente) {
            try {
                await navigator.clipboard.writeText(linkCliente);
                this.$swal.fire("Copiado!", "Link copiado al portapapeles", "success");
                /* Resuelto - texto copiado al portapapeles con éxito */
            } catch (err) {
                console.error('Error al copiar: ', err);
                /* Rechazado - fallo al copiar el texto al portapapeles */
            }
        }


    },
    created()
    {
        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
    },
    mounted()
    {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });
    }
}
</script>
