<template>
	<div>
		<CList :pConfigList="ConfigList" :segurity="segurity">
			<template slot="bodyForm">
			<CLoader :pConfigLoad="ConfigLoad">
        		<template slot="BodyFormLoad">
				<form id="FormReporteanomalia" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
					<div class="container-fluid">

						<div class="row">
							<div class="col-12 col-sm-12 col-md-12 col-lg-12">
								<div class="form-group form-row">

                                    <div v-if="isOnlyView && parseInt(objReporteanomalia.IdAnomaliaReporte) > 0" class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <label for="FolioAnomalia">Folio Interno</label>
										<input type="text" v-model="objReporteanomalia.FolioInterno" class="form-control" id="FolioAnomalia" disabled />
                                    </div>

									<div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <label for="FechaAnomalia">Fecha <span v-show="!isOnlyView"  style="color:red">*</span></label>
										<v-date-picker v-model="objReporteanomalia.FechaAnomalia" :masks="masks" :popover="{visibility: 'focus' }" locale="es">
											<template v-slot="{ inputValue, inputEvents }">
												<input class="form-control" :value="inputValue" v-on="inputEvents" :readonly="!isOnlyView" :disabled="isOnlyView"/>
											</template>
										</v-date-picker>
										<CValidation v-if="this.errorvalidacion.FechaAnomalia" :Mensaje="'*'+errorvalidacion.FechaAnomalia[0]"/>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <label for="FolioAnomalia">Folio Anomalia</label>
										<input type="text" v-model="objReporteanomalia.FolioAnomalia" class="form-control" id="FolioAnomalia" placeholder="AAG-00-00" :disabled="isOnlyView" />
										<CValidation v-if="this.errorvalidacion.FolioAnomalia" :Mensaje="'*'+errorvalidacion.FolioAnomalia[0]"/>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <label for="FolioCotizacion">Folio Cotizacion <span v-show="!isOnlyView" style="color:red">*</span></label>
										<input type="text" v-model="objReporteanomalia.FolioCotizacion" class="form-control" id="FolioCotizacion" placeholder="LAB-00000-00" :disabled="isOnlyView"/>
										<CValidation v-if="this.errorvalidacion.FolioCotizacion" :Mensaje="'*'+errorvalidacion.FolioCotizacion[0]"/>
                                    </div>

                                    <div class="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                        <label for="FolioOrdenTrabajo">Folio Orden de Trabajo <span v-show="!isOnlyView" style="color:red">*</span></label>
										<input type="text" v-model="objReporteanomalia.FolioOrdenTrabajo" class="form-control" id="FolioOrdenTrabajo" placeholder="OT-T-000" :disabled="isOnlyView"/>
										<CValidation v-if="this.errorvalidacion.FolioOrdenTrabajo" :Mensaje="'*'+errorvalidacion.FolioOrdenTrabajo[0]"/>
                                    </div>

                                </div>
		
								<div class="form-group form-row">
									
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
										<label for="Cliente">Cliente <span v-show="!isOnlyView" style="color:red">*</span></label>
										<input type="text" v-model="objReporteanomalia.Cliente" class="form-control" id="Cliente" placeholder="Ingresar Cliente" :disabled="isOnlyView"/>
										<CValidation v-if="this.errorvalidacion.Cliente" :Mensaje="'*'+errorvalidacion.Cliente[0]"/>
									</div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
										
									</div>
								</div>
		
			
								<div class="form-group form-row">
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
										<label for="DescripcionGeneral">Descripción de Anomalia <span v-show="!isOnlyView" style="color:red">*</span></label>
										<textarea v-model="objReporteanomalia.DescripcionGeneral" class="form-control" id="DescripcionGeneral" placeholder="Ingresar Descripcion" rows="3" :disabled="isOnlyView"></textarea>
										<CValidation v-if="this.errorvalidacion.DescripcionGeneral" :Mensaje="'*'+errorvalidacion.DescripcionGeneral[0]"/>
									</div>
								</div>

                        
                                <div class="row">
									<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
											<legend class="col-form-label">&nbsp;Evidencias&nbsp;</legend>
                                            <hr>
											<div class="form-row form-inline">
												<div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
													<h6 class="text-center col-form-h1" v-if="ListaEvidenciaArray == 0">No contiene registros</h6>
												</div>
												<div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
													<button type="button" @click="AgregarItemEvidencia()" v-if="ListaEvidenciaArray.length < 6 && isOnlyView === false" class="btn btn-sm btn-primary float-right"><i class="fa fa-plus-circle"></i></button>
												</div>
											</div>

											<div class="form-group form-row">
												<div class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 mt-5" v-for="(item3,index3) in ListaEvidenciaArray" :key="index3">
													<div class="box-imagen" v-if="item3.Evidencia==''">
														<div class="avatar-upload">

                                                            <div class="avatar-edit">
                                                                <input type="file" :id="'fileEmp'+index3" name="file3[]" ref="file3" @change="$uploadImagePreviewArray($event,ValidElement,item3, Array('Evidencia','ImagenTmp','RutaEvid'))" accept=".png, .jpg, .jpeg">
																<label :for="'fileEmp'+index3"  class="mr-1"></label>
                                                            </div>
                                                            <div class="avatar-edit2">
                                                                <button type="button" @click="EliminarItemEvidencia(item3,index3);">
                                                                    <i class="fas fa-trash"></i>
                                                                </button>
                                                            </div>


															<div class="avatar-preview">
																<div :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+item3.ImagenTmp+');'" :src="(item3.ImagenTmp!='')?item3.ImagenTmp:''"></div>
															</div>

													
														</div>
													</div>

													<div v-else class="box-imagen">
														<div class="avatar-upload image-button">
                                                            
                                                            <div class="avatar-edit">
                                                                
                                                            </div>
                                                            <div class="avatar-edit2">
                                                                <button type="button" class="mr-1" @click="VerPreviewImagen(item3)">
																	<i class="fas fa-search"></i>
																</button>
                                                            </div>

															<div class="avatar-preview">
																<div :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+RutaEvid+item3.Evidencia+');'" :src="RutaEvid+item3.Evidencia"></div>
															</div>

														
														</div>
													</div>
												</div>
											</div>
										
                                    </div>
                                </div>
											
								<br>
								<CBtnSave v-if="!isOnlyView" :poBtnSave="oBtnSave" />

							</div><!--fin col-12-->
						</div>


                    </div>
                </form>
				</template>
    		</CLoader>
            </template>
        </CList>
    </div>
   
</template>
<script>
import CBtnSave     from '../../components/CBtnSave.vue';
import Configs 	    from '@/helpers/VarConfig.js';
import moment       from 'moment';
const EmitEjecuta = 'seccionReporteanomalia';


export default {
    name: 'FormReporteanomalia',
    props: ['Id'],
    components:{
        CBtnSave
    },
    data() {
        return {
			ConfigList:{
                Title: 'Formulario Anomalias',
                IsModal: false,
                BtnReturnShow: true,
                BtnNewShow: false,
                ShowFiltros: false,
                ShowPaginador: false,
                TypeBody: 'Form',
				EmitSeccion: EmitEjecuta,
                greenHeader:true
            },
			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
			oBtnSave:{
                toast: 0,
                IsModal: false,
                ShowBtnSave:   true,
				ShowBtnCancel: true,
                DisableBtn: false,
                ReturnRoute: 'anomalias',
                EmitSeccion: EmitEjecuta,
            },

            objReporteanomalia: {
				IdAnomaliaReporte: 0,
                Cliente: '',
				FechaAnomalia: '',
                FolioAnomalia: '',
				FolioCotizacion: '',
				FolioOrdenTrabajo: '',
				DescripcionGeneral: '',
            },
            segurity: {},
			masks: { input: 'YYYY-MM-DD' }, // quitar sino lleva fecha 
            errorvalidacion: [],
			Emit: EmitEjecuta,

            ListaEvidenciaArray:[],
            ValidElement: 				Configs.validImage5m,
            RutaEvid: '',
            isOnlyView: false,
            isviewClient: false,
            queryKey: ''
        }
    },
    methods :
    {
       	async Guardar()
        {      
            

            let Fecha1 = '';
			if(this.objReporteanomalia.FechaAnomalia!=''){
                Fecha1 = moment(this.objReporteanomalia.FechaAnomalia).format('YYYY-MM-DD');
            }
            this.objReporteanomalia.FechaAnomalia = Fecha1;


            let formData = new FormData();
            formData.set("IdAnomaliaReporte", 	this.objReporteanomalia.IdAnomaliaReporte);
            formData.set("Cliente", 			this.objReporteanomalia.Cliente);
            formData.set("FechaAnomalia", 		this.objReporteanomalia.FechaAnomalia);
            formData.set("FolioAnomalia", 		this.objReporteanomalia.FolioAnomalia);
            formData.set("FolioCotizacion", 	this.objReporteanomalia.FolioCotizacion);
            formData.set("FolioOrdenTrabajo", 	this.objReporteanomalia.FolioOrdenTrabajo);
            formData.set("DescripcionGeneral", 	this.objReporteanomalia.DescripcionGeneral);

            let files3 = document.getElementsByName('file3[]');

            for(var i=0;i<this.ListaEvidenciaArray.length;i++) {
                let file = (files3[i])?files3[i].files[0]:'';
                // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA IMAGEN COMO CAMPO OBLIGATORIO
                if(this.ListaEvidenciaArray[i].Evidencia =='' && file===undefined && this.ListaEvidenciaArray[i].IdAnomaliaReporte == 0) {
                    this.$toast.warning('La fotografía '+(i+1)+' debe ser agregada');
                    return false;
                } else {
                    if(file!=undefined){
                        formData.append('FileEvi[]',file);
                    }
                }
            }

            this.errorvalidacion = [];
            this.oBtnSave.toast = 0; 
            this.oBtnSave.DisableBtn = true;

            await this.$http.post('reportesanomalias',formData,{
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
            .then((res)=>{
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
			
        },

		EjecutaConExito(res)
        {
            this.oBtnSave.DisableBtn = false;  
            this.bus.$emit('RunAlerts_'+this.Emit,1);
            this.Regresar();
        },

        EjecutaConError(err)
        {
            this.oBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.oBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },

		Recuperar()
        {   
            this.isOnlyView = true;
            //this.ConfigList.BtnReturnShow = false;
            //this.ConfigList.ShowTitleFirst = false;
            //this.ConfigList.ShowTitleEnd = false;

            this.$http.get("reportesanomalias/"+this.objReporteanomalia.IdAnomaliaReporte,{
                params:{
                    useFeat: (this.isviewClient) ? 1 : 0
                }
            })
            .then((res) => {
                this.objReporteanomalia     = res.data.data;
                this.ListaEvidenciaArray    = this.objReporteanomalia.Evidencias;
                this.RutaEvid               = res.data.RutaFile;
				// en caso de que necesite fecha
				let formatedDate1 = this.objReporteanomalia.FechaAnomalia.replace(/-/g,'\/');
                this.objReporteanomalia.FechaAnomalia = new Date(formatedDate1);
                this.objReporteanomalia.FolioInterno = 'RPA-'+this.objReporteanomalia.IdAnomaliaReporte

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },

        Limpiar()
        {
            this.objReporteanomalia = {
				IdAnomaliaReporte: 0,
                Cliente: '',
				FechaAnomalia: '',
                FolioAnomalia: '',
				FolioCotizacion: '',
				FolioOrdenTrabajo: '',
				DescripcionGeneral: '',
            }
            this.errorvalidacion = [];
        },
		Regresar() {
            this.$router.push({name:'anomalias'});
            // this.$router.push({name:'routeraregresar',params:{obj:this.obj}});
        },

        AgregarItemEvidencia() {
            let newObj = { IdAnomaliaReporte : 0, Evidencia: "", ImagenTmp: '' };

            if(this.ListaEvidenciaArray.length < 6) {
                this.ListaEvidenciaArray.push(newObj);
            }

		},

        EliminarItemEvidencia(item,index) {
            let Id   = item.IdClienteEvidencia;
            let Tipo = item.TipoEvidencia;

            this.$swal(Configs.configEliminar)
            .then((result) => {

                if (result.value) {
                    this.ListaEvidenciaArray.splice(index,1);
                }
            });
        },

        VerPreviewImagen(item){
            window.open(this.RutaEvid+item.Evidencia,'Nueva Ventana',"width=600,height=800");
        }
			
    },
	created() {


        if(this.$getParameterByName('feat') != null && this.$getParameterByName('feat') != undefined){
            this.queryKey = this.$getParameterByName('feat');
        }


        this.oBtnSave.toast = 0;
        this.ConfigLoad.ShowLoader = true;

        this.bus.$off('EmitReturn');
        this.bus.$off('Save_'+this.Emit);
    },
    mounted()
    {
		this.oBtnSave.DisableBtn = false;
        this.Limpiar();

        if(this.queryKey != '' && this.queryKey != undefined) {
            this.objReporteanomalia.IdAnomaliaReporte = this.queryKey;
            this.isviewClient = true;
            this.ConfigList.BtnReturnShow = false;
            this.ConfigList.ShowTitleFirst = false;
            this.ConfigList.ShowTitleEnd = false;
            this.Recuperar();

        }else {
            this.isviewClient = false;
            if(this.Id != '') 
            {
                this.objReporteanomalia.IdAnomaliaReporte = this.Id;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        }
        

        this.bus.$on('Save_'+this.ConfigList.EmitSeccion,()=>
        {
            this.Guardar();
        });

        this.bus.$on('EmitReturn',()=>
        {
            this.Regresar();
        });
    }
}
</script>
