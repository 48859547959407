
import DashboardTickes      from "../views/modulos/dashboardtickes/ViewPanelTickets";
import ListaEmpresaSoporte  from "../views/catalogos/empresasoporte/List";
import Sucursales           from "../views/catalogos/sucursales/List.vue";
import Empresa              from "../views/catalogos/empresas/Form.vue";
import UsuarioSucursal      from "../views/catalogos/usuarioSucursal/List.vue";
import Usuarios             from "../views/catalogos/usuarios/List.vue";
import Departamentos        from "../views/catalogos/departamentos/List.vue";
import Perfiles             from "../views/catalogos/perfiles/List.vue";
import Puestos              from "../views/catalogos/puestos/List.vue";
import Correo               from "../views/catalogos/correo/Form.vue";
import mensajeCorreo        from "../views/catalogos/mensajeDefault/List.vue";
import EquiposMantenimiento from "../views/catalogos/equiposmantenimiento/List.vue";
import Mantenimientos       from "../views/catalogos/bitacoramantenimiento/List.vue";
import EquipoLicencias       from "../views/catalogos/equiposlicencias/List.vue";

const VarRutasSoporte = [
    {
        path: '/empresasoporte',
        name: 'empresasoporte',
        component: ListaEmpresaSoporte,
        props: true
    },
    {
        path: '/dashboardtickes',
        name: 'dashboardtickes',
        component: DashboardTickes,
        props: true
    },
    {
        path: "/Empresa",
        name: "empresa",
        component: Empresa,
        props: true,
    },
    {
        path: "/Sucursales",
        name: "sucursales",
        component: Sucursales,
        props: true,
    },
    {
        path: "/UsuariosSucursal",
        name: "usuariosSucursal",
        component: UsuarioSucursal,
        props: true,
    },
    {
        path: "/Departamentos",
        name: "departamentos",
        component: Departamentos,
        props: true,
    },
    {
        path: '/Perfiles',
        name: 'perfiles',
        component: Perfiles,
        props: true
    },
    {
        path: '/Puestos',
        name: 'puestos',
        component: Puestos,
        props: true
    },
    {
        path: '/ConfiguracionCorreoElectronico',
        name: 'correo',
        component: Correo,
        props: true
    },
    {
        path: '/mensajesCorreos',
        name: 'mensajeCorreo',
        component: mensajeCorreo,
        props: false
    },
    {
        path: '/equiposmantenimiento',
        name: 'equiposmantenimiento',
        component: EquiposMantenimiento,
        props: true
    },
    {
        path: '/mantenimientos',
        name: 'mantenimientos',
        component: Mantenimientos,
        props: true
    },
    {
        path: '/equipolicencias',
        name: 'equipolicencias',
        component: EquipoLicencias,
        props: true
    },
];

export default {
    VarRutasSoporte,
}
