<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
                <select v-if="ObjUsuario.IdPerfil === 2" @change="Lista" class="form-control form-select" v-model="Filtro.typeInfo" style="width: 115px;">
                    <option value="me">Mis Tickets</option>
                    <option value="all">Ver todos</option>
                </select>
            </template>

            <template slot="header">
                <th class="text-center">Folio</th>
                <th class="text-center">Fecha</th>
                <th>Asunto</th>
                <th class="text-center">Prioridad</th>
                <th class="text-center">Estatus</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="text-center"><b>#{{ lista.Folio }}</b></td>
                    <td class="text-center">{{ $getCleanDate(lista.created_at,true) }}</td>
                    <td>{{ lista.Asunto }}</td>
                    <td class="text-center">
                        <span v-if="lista.TipoPrioridad == '1'" class="badge badge-info" style="width: 80px;">Baja</span>
                        <span v-else-if="lista.TipoPrioridad == '2'" class="badge badge-warning" style="width: 80px;">Media</span>
                        <span v-else-if="lista.TipoPrioridad == '3'" class="badge badge-danger" style="width: 80px;">Alta</span>
                    </td>
                    <td class="text-center">
                        <span v-if="lista.Estatus == 'Pendiente'" class="badge badge-secondary" style="width: 80px;">Pendiente</span>
                        <span v-else-if="lista.Estatus == 'Proceso'" class="badge badge-primary" style="width: 80px;">En Proceso</span>
                        <span v-else-if="lista.Estatus == 'Atendido'" class="badge badge-warning" style="width: 80px;">Atendido</span>
                        <span v-else-if="lista.Estatus == 'Cerrado'" class="badge badge-danger" style="width: 80px;">Cerrado</span>

                    </td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="true" :pId="lista.IdTicket" :pEmitSeccion="ConfigList.EmitSeccion">
							<template slot="btnaccion">
                                <button type="button" v-b-tooltip.hover.Top title="Ir al Ticket" class="btn btn-primary btn-icon mr-1" @click="viewTickets(lista.IdTicket)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </template>
						</CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="8"></CSinRegistro>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormTicket :poBtnSave="oBtnSave"></FormTicket>
			</template>
		</CModal>
    </div>
</template>

<script>

    import FormTicket     from '@/views/catalogos/ticket/Form.vue';
    import StaticComboBox from '@/helpers/StaticComboBox.js';
    import Configs        from '@/helpers/VarConfig.js';
    import moment         from 'moment';
    import CList          from "../../../components/CList";
    const EmitEjecuta     =    'seccionTicket';

    export default {
        name:  'ListaTicket',
		props: ['mode'],
        components: {
            FormTicket,
            moment,
            CList
        },
        data() {
            return {
                Departamento:   StaticComboBox.Departamento,
                ListaArrayRows: [],
                segurity:       {},
                obj:            {},
                ObjUsuario:     {},
                ConfigList:{
                    Title:         'Listado Tickets de Soporte',
                    BtnNewName:    'Abrir Ticket',
                    IsModal:       true,
                    ShowLoader:    true,
                    BtnReturnShow: false,
                    GoRoute:       'viewticket',
                    EmitSeccion:   EmitEjecuta,
                },
                Filtro:{
                    Nombre:      '',
                    Pagina:      1,
                    Entrada:     10,
                    TotalItem:   0,
                    Placeholder: 'Buscar..',
                    typeInfo: 'me',
                },
                ConfigModal:{
                    ModalTitle:     "Formulario Ticket",
                    ModalNameId:    'ModalForm',
                    ModalSize:      'xl',
                    EmitSeccion:    EmitEjecuta,
                    ModalModethema: 'dark'
                },
                oBtnSave: {
                    toast:       0,
                    IsModal:     true,
                    DisableBtn:  false,
                    EmitSeccion: EmitEjecuta,
                },
            }
        },
        methods: {
            async Lista() {
                this.ConfigList.ShowLoader = true;

                await this.$http.get('tickets', {
                    params: {
                        TxtBusqueda: this.Filtro.Nombre,
                        Entrada:     this.Filtro.Entrada,
                        Pag:         this.Filtro.Pagina,
                        IdUsuario:   this.ObjUsuario.IdUsuario,
                        IdEmpresa:   this.ObjUsuario.IdEmpresa,
                        IdSucursal:  this.ObjUsuario.IdSucursal,
                        typeInfo:    this.Filtro.typeInfo,
                    }
                }).then( (res) => {
                    this.ListaArrayRows     = res.data.data.Tickets.data;
                    this.Filtro.Pagina      = res.data.data.Tickets.current_page;
                    this.Filtro.TotalItem   = res.data.data.Tickets.total;
                }).finally(()=>{
                    this.ConfigList.ShowLoader = false;
                });
            },
            Eliminar(Id) {
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value) {
                        this.$http.delete('tickets/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            CMBFiltrado(buscar) {
                let aBuscar  = buscar;
                let devuelve = '';
                let arr      = this.Departamento.filter(function(vl){

                    if(aBuscar!=''){

                        if(aBuscar == vl.id){
                            return vl;
                        } else {
                            return '';
                        }

                    }

                });

                if(arr[0]){
                    devuelve = arr[0].label;
                }

                return devuelve;
            },

			Session() {
				this.ObjUsuario = JSON.parse(sessionStorage.getItem("user"));
			},
            viewTickets(Id) {
                this.$router.push({
                    name:this.ConfigList.GoRoute,
                    params:{
                        Id:Id,
                        Origen:'Lista'
                    }
                })
            },

            timer() {
                this.IntervalTime = setInterval(
                    function() {
                        this.Lista("");
                    }.bind(this),
                    360000
                );
            },
        },
        created() {
            this.bus.$off('Delete_'+EmitEjecuta);
            this.bus.$off('List_'+EmitEjecuta);
			this.Session();
        },
        mounted() {
            this.Lista();
            this.timer();

            this.bus.$on('Delete_'+EmitEjecuta,(Id)=> {
                this.Eliminar(Id);
            });

            this.bus.$on('List_'+EmitEjecuta,()=> {
                this.Lista();
            });
        },
    }

</script>
