<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="container-fluid">

                    <div class="row form-group">

                        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div class="avatar-upload">
                                <div class="avatar-edit">
                                    <input  type="file" @change="$uploadImagePreview($event,ValidElement,Array('Img','imagePreview1'))" id="file1" name="myfile"  ref="file1" accept=".png, .jpg, .jpeg">
                                    <label for="file1"></label>
                                </div>
                                <div class="avatar-preview">
                                    <div id="imagePreview1" :style="'background-image: url('+RutaFile+objEquipo.Evidencia1+');'" :src="RutaFile+objEquipo.Evidencia1">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div class="avatar-upload">
                                <div class="avatar-edit">
                                    <input  type="file" @change="$uploadImagePreview($event,ValidElement,Array('Img','imagePreview2'))" id="file2" name="myfile"  ref="file2" accept=".png, .jpg, .jpeg">
                                    <label for="file2"></label>
                                </div>
                                <div class="avatar-preview">
                                    <div id="imagePreview2" :style="'background-image: url('+RutaFile+objEquipo.Evidencia2+');'" :src="RutaFile+objEquipo.Evidencia2">
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>



                    <div class="row form-group mt-4">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <label for="item7">Departamento</label>
                            <select v-model="objEquipo.IdDepartamento" class="form-control form-select">
                                <option :value="0">--Seleccionar--</option>
                                <option v-for="(item, index) in listaDepartamentos" :key="index" :value="item.IdDepartamento">
                                    {{ item.Nombre }}
                                </option>
                            </select>
                            <CValidation v-if="this.errorvalidacion.IdDepartamento" :Mensaje="'*' +errorvalidacion.IdDepartamento[0]"/>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Marca</label>
                            <input type="text" v-model="objEquipo.Marca" class="form-control" placeholder="Ingresar Marca"/>
                            <CValidation v-if="this.errorvalidacion.Marca" :Mensaje="'*' +errorvalidacion.Marca[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Modelo</label>
                            <input type="text" v-model="objEquipo.Modelo" class="form-control" placeholder="Ingresar Modelo"/>
                            <CValidation v-if="this.errorvalidacion.Modelo" :Mensaje="'*' +errorvalidacion.Modelo[0]"/>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">No. Serie</label>
                            <input type="text" v-model="objEquipo.NoSerie" class="form-control" placeholder="Ingresar No Serie"/>
                            <CValidation v-if="this.errorvalidacion.NoSerie" :Mensaje="'*' +errorvalidacion.NoSerie[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Nombre Equipo en Red</label>
                            <input type="text" v-model="objEquipo.NombreEquipoRed" class="form-control" placeholder="Ingresar Nombre Equipo Red"/>
                            <CValidation v-if="this.errorvalidacion.NombreEquipoRed" :Mensaje="'*' +errorvalidacion.NombreEquipoRed[0]"/>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">No. Producto Windows</label>
                            <input type="text" v-model="objEquipo.NoProductoWindows" class="form-control" placeholder="Ingresar No Producto Windows"/>
                            <CValidation v-if="this.errorvalidacion.NoProductoWindows" :Mensaje="'*' +errorvalidacion.NoProductoWindows[0]"/>
                        </div>
                    </div>

                    <div class="row form-group">

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Estatus</label>
                            <select v-model="objEquipo.Estatus" class="form-control form-select">
                                <option :value="0">--Seleccionar--</option>
                                <option v-for="(item, index) in ListaEstatus" :key="index" :value="item.label">
                                    {{ item.label }}
                                </option>
                            </select>
                            <CValidation v-if="this.errorvalidacion.Estatus" :Mensaje="'*' +errorvalidacion.Estatus[0]"/>
                        </div>

                        <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                            <label for="Telefono">Observaciones Generales</label>
                            <textarea v-model="objEquipo.Observaciones" class="form-control" placeholder="Ingresar Descripciones" rows="3"></textarea>

                        </div>
                    </div>




                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>
import CLoader from "../../../components/CLoader.vue";
import CValidation from "../../../components/CValidation.vue";
import VarConfig from "../../../helpers/VarConfig";
import StaticComboBox from "../../../helpers/StaticComboBox";

export default {
    name: "FormSucursal",
    props: ["poBtnSave","poSucursal"],
    components: {
        CLoader,
        CValidation
    },
    data() {
        return {
            RutaFile:        '',
            Img:             null,
            Emit:            this.poBtnSave.EmitSeccion,
            ValidElement:    VarConfig.validImage10m,
            ListaEstatus:      StaticComboBox.EstatusEquipo,
            errorvalidacion: [],
            listaDepartamentos: [],
            localSucursal: {},

            ConfigLoad: {
                ShowLoader: true,
                ClassLoad: false,
            },

            objEquipo: {
                IdEquipo:0,
                IdEmpresa: 0,
                IdSucursal: 0,
                IdDepartamento: '0',
                Marca: "",
                Modelo: "",
                NoSerie: "",
                NombreEquipoRed: "",
                NoProductoWindows: "",
                Estatus: '0',
                Observaciones: "",
                Evidencia1: "",
                Evidencia2: "",
            },


        };
    },
    methods: {
        async Guardar() {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0;
            this.poBtnSave.DisableBtn = true;

            let formData = new FormData();
            formData.set('IdEquipo',            this.objEquipo.IdEquipo);
            formData.set('IdEmpresa',           this.localSucursal.IdEmpresa);
            formData.set('IdSucursal',          this.localSucursal.IdSucursal);
            formData.set('IdDepartamento',      this.objEquipo.IdDepartamento);
            formData.set('Marca',               this.objEquipo.Marca);
            formData.set('Modelo',              this.objEquipo.Modelo);
            formData.set('NoSerie',             this.objEquipo.NoSerie);
            formData.set('NombreEquipoRed',     this.objEquipo.NombreEquipoRed);
            formData.set('NoProductoWindows',   this.objEquipo.NoProductoWindows);
            formData.set('Estatus',             this.objEquipo.Estatus);
            formData.set('Observaciones',       this.objEquipo.Observaciones);


            if(this.$refs.file1.files[0] != undefined){
                let picture = this.$refs.file1.files[0];
                formData.append('Evidencia1',picture);
            }

            if(this.$refs.file2.files[0] != undefined){
                let picture2 = this.$refs.file2.files[0];
                formData.append('Evidencia2', picture2);
            }


            this.$http.post("equipos", formData,{
				'Content-Type':'multipart/form-data'

			}).then((res) => {

                this.EjecutaConExito(res);

            }).catch((err) => {
                this.EjecutaConError(err);
            });

        },
        EjecutaConExito(res) {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit("CloseModal_" + this.Emit);
            this.bus.$emit("List_" + this.Emit);
        },
        EjecutaConError(err) {
            this.poBtnSave.DisableBtn = false;

            if (err.response.data.errors) {
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar() {
            this.$http
                .get("equipos/" + this.objEquipo.IdEquipo)
                .then((res) => {
                    this.objEquipo = res.data.data;
                    this.RutaFile = res.data.RutaFile;
                })
                .finally(() => {
                    this.ConfigLoad.ShowLoader = false;
                });
        },

        Limpiar() {
            this.objEquipo = {
                IdEquipo: 0,
                IdEmpresa: 0,
                IdSucursal: 0,
                IdDepartamento: '0',
                Marca: "",
                Modelo: "",
                NoSerie: "",
                NombreEquipoRed: "",
                NoProductoWindows: "",
                Estatus: '0',
                Observaciones: "",
            };
            this.errorvalidacion = [];
        },
        /*
       * Listado de estados del combo
       */
        async getDepartamentos() {
            await this.$http.get('departamentos').then(res => {
                this.listaDepartamentos = res.data.data.Departamento.data;
            }).catch(err =>{
                this.listaDepartamentos = [];
            }).finally( () => {
            });
        },


    },
    created() {
        this.getDepartamentos();

        this.poBtnSave.toast = 0;
        this.bus.$off("Recovery_" + this.Emit);


        this.bus.$on("Recovery_" + this.Emit, (Id) => {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off("Save_" + this.Emit);
            this.bus.$on("Save_" + this.Emit, () => {
                this.Guardar();

            });
            this.Limpiar();

            console.log(Id);

            if (Id != "") {
                this.objEquipo.IdEquipo = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },

    mounted() {
        if(this.poSucursal != undefined){
            this.localSucursal = this.poSucursal;
        }
    }
};
</script>
