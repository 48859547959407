<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div v-if="objTickets.IdTicket < 1" class="form-group form-row justify-content-between">                        
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label for="">Fecha Actual</label>
                            <input v-model="objTickets.FechaActual" disabled type="text" class="form-control" locale="es">
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label for="">Tipo de Asistencia</label>
                            <select name="item7" id="item7" class="form-control form-select" v-model="objTickets.TipoTicket">
                                <option value="">--Seleccionar--</option> 
                                <option value="Asistencia Tecnica">Asistencia Tecnica</option> 
                                <option value="Soporte Proceso">Soporte de Proceso</option>    
                                <option value="Mejora de Sistema">Mejora de Sistema</option>  
                            </select>
                            <CValidation v-if="this.errorvalidacion.TipoTicket" :Mensaje="'*' + errorvalidacion.TipoTicket[0]"/>
                        </div>
                    </div>
                    
                    <div v-else class="form-group form-row justify-content-end">     
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label for="">Tipo de Asistencia</label>
                            <select name="item7" id="item7" class="form-control form-select" v-model="objTickets.TipoTicket">
                                <option value="">--Seleccionar--</option> 
                                <option value="Asistencia Tecnica">Asistencia Tecnica</option> 
                                <option value="Soporte Proceso">Soporte de Proceso</option>  
                                <option value="Mejora de Sistema">Mejora de Sistema</option>      
                            </select>
                            <CValidation v-if="this.errorvalidacion.TipoTicket" :Mensaje="'*' + errorvalidacion.TipoTicket[0]"/>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">CONTACTO</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Nombre</label>
                                            <input v-model="ObjUsuario.Nombre" disabled type="text" name="" id="" class="form-control" placeholder="Ingresa un Nombre">
                                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*' + errorvalidacion.Nombre[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Correo Electrónico</label>
                                            <input v-model="objTickets.Correo" disabled type="text" name="" id="" class="form-control" placeholder="Ingresa un Correo Electrónico">
                                            <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*' + errorvalidacion.Correo[0]"/>
                                        </div>
                                    </div>
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Prioridad</label>
                                            <select name="item7" id="item7" class="form-control form-select" v-model="objTickets.TipoPrioridad">
                                                <option value="">--Seleccionar--</option>
                                                <option v-for="(item, index) in TipoPrioridad" :key="index" :value="item.id">{{item.label}}</option>
                                            </select>
                                            <CValidation v-if="this.errorvalidacion.TipoPrioridad" :Mensaje="'*' + errorvalidacion.TipoPrioridad[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Teléfono</label>
                                            <input v-model="objTickets.Telefono" @input="$onlyNums($event,objTickets,'Telefono')" type="text" name="" id="" class="form-control"  placeholder="Ingresa un Teléfono">
                                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' + errorvalidacion.Telefono[0]"/>
                                        </div>
                                    </div>  
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <label for="">Asunto</label>
                                            <input v-model="objTickets.Asunto" type="text" name="" id="" class="form-control"  placeholder="Ingresa un Asunto">
                                            <CValidation v-if="this.errorvalidacion.Asunto" :Mensaje="'*' + errorvalidacion.Asunto[0]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    
                    <div v-if="objTickets.TipoTicket == 'Soporte Proceso'" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">DATOS DE REFERENCIA</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="">Cotización</label>
                                            <input v-model="objTickets.Cotizacion" type="text" name="" id="" class="form-control" placeholder="Ingresa un Cotización">
                                            <CValidation v-if="this.errorvalidacion.Cotizacion" :Mensaje="'*' + errorvalidacion.Cotizacion[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="">Recepción</label>
                                            <input v-model="objTickets.Recepcion" type="text" name="" id="" class="form-control" placeholder="Ingresa un Recepción">
                                            <CValidation v-if="this.errorvalidacion.Recepcion" :Mensaje="'*' + errorvalidacion.Recepcion[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="">Orden de Trabajo</label>
                                            <input v-model="objTickets.OrdenTrabajo" type="text" name="" id="" class="form-control" placeholder="Ingresa un Orden de Trabajo">
                                            <CValidation v-if="this.errorvalidacion.OrdenTrabajo" :Mensaje="'*' + errorvalidacion.OrdenTrabajo[0]"/>
                                        </div>
                                    </div>                     
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                            <label for="">Calibración Externa</label>
                                            <input v-model="objTickets.CalibracionExt" type="text" name="" id="" class="form-control" placeholder="Ingresa un Calibración Externa">
                                            <CValidation v-if="this.errorvalidacion.CalibracionExt" :Mensaje="'*' + errorvalidacion.CalibracionExt[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                            <label for="">Requisición</label>
                                            <input v-model="objTickets.Requisicion" type="text" name="" id="" class="form-control" placeholder="Ingresa un Requisición">
                                            <CValidation v-if="this.errorvalidacion.Requisicion" :Mensaje="'*' + errorvalidacion.Requisicion[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                            <label for="">Orden de Compra</label>
                                            <input v-model="objTickets.OrdenCompra"  type="text" name="" id="" class="form-control" placeholder="Ingresa un Orden de Compra">
                                            <CValidation v-if="this.errorvalidacion.OrdenCompra" :Mensaje="'*' + errorvalidacion.OrdenCompra[0]"/>
                                        </div>                       
                                        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                            <label for="">Otro</label>
                                            <input v-model="objTickets.Otro" type="text" name="" id="" class="form-control" placeholder="Ingresa una descripción">
                                        </div>
                                    </div>                                                                      
                                    <div class="form-group form-row"> 
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Motivo de solicitud</label>
                                            <select name="item7" id="item7" class="form-control form-select" v-model="objTickets.MotivoSolicitud">
                                                <option value="">--Seleccionar--</option>
                                                <option v-for="(item, index) in MotivoSolicitud" :key="index" :value="item.id">{{item.label}}</option>
                                            </select>
                                            <CValidation v-if="this.errorvalidacion.MotivoSolicitud" :Mensaje="'*' + errorvalidacion.MotivoSolicitud[0]"/>
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Area donde se presento el error</label>
                                            <treeselect v-model="SelectDepartamento" :options="Departamento" :multiple="true" :show-count="true" :value-consists-of="'ALL_WITH_INDETERMINATE'" placeholder="Seleccione un Departamento"/>
                                            <CValidation v-if="this.errorvalidacion.Departamento" :Mensaje="'*' + errorvalidacion.Departamento[0]"/>
                                        </div>
                                        <div v-if="SelectDepartamento == 6" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <label for="">Nuevo Departamento</label>
                                            <input v-model="objTickets.NuevoDepartamento" type="text" name="" id="" class="form-control" placeholder="Ingresa un Departamento">
                                            <CValidation v-if="this.errorvalidacion.NuevoDepartamento" :Mensaje="'*' + errorvalidacion.NuevoDepartamento[0]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">DESCRIPCIÓN</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 quillEditorcol">
                                    <label for="">Mensaje</label>
                                    <quill-editor v-model="objTickets.Descripcion" ref="quillEditorAC" :options="editorOption" class="quillEditor"></quill-editor>
                                    <CValidation v-if="this.errorvalidacion.Descripcion" :Mensaje="'*' + errorvalidacion.Descripcion[0]"/>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">EVIDENCIA FOTOGRAFICA</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                                    <div class="form-row mt-3 justify-content-end">
                                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 mb-2">
                                            <button type="button" @click="AgregarItem" v-if="ListaDetalle.length<3" v-b-tooltip.hover.Top title="Agregar Evidencia" class="btn-primary btn-icon-tabla2">
                                                <i class="fas fa-plus"></i><span class="tiptext"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" v-for="(item3,index3) in ListaDetalle" :key="index3">
                                            <div class="box-imagen" v-if="item3.Evidencia==''">
                                                <div class="avatar-upload image-button">
                                                    <div class="avatar-preview">
                                                        <img v-if="item3.ImagenTmp == ''" src="@/assets/style/image/avatar.png" alt="">
                                                        <div v-else :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+item3.ImagenTmp+');'" :src="(item3.ImagenTmp!='')?item3.ImagenTmp:''"></div>
                                                    </div>

                                                    <div class="icons-button">
                                                        <input type="file" :id="'fileEmp'+index3" name="file3[]" ref="file3" @change="$uploadImagePreviewArray($event,ValidElement,item3, Array('Evidencia','ImagenTmp','RutaEvid'))" accept=".png, .jpg, .jpeg">
                                                        <label :for="'fileEmp'+index3"  class="mr-1"></label>

                                                        <button type="button" @click="Eliminar(index3);">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else class="box-imagen">
                                                <div class="avatar-upload image-button">
                                                    <div class="avatar-preview">
                                                        <div :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+RutaEvid+item3.Evidencia+');'" :src="RutaEvid+item3.Evidencia"></div>
                                                    </div>

                                                    <div class="icons-button">
                                                        <button type="button" @click="VerPreviewImagen(item3)" class="mr-1">
                                                            <i class="fas fa-search"></i>
                                                        </button>

                                                        <button type="button" @click="EliminarItemEvidencia(item3,index3);">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="mb-3">
                            <legend class="legend-form">EVIDENCIA - PDF</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="form-row mt-3 justify-content-end">
                                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 mb-2">
                                            <button type="button" @click="AgregarItemFile" v-if="ListaDetalleFile.length<3" v-b-tooltip.hover.Top title="Agregar Evidencia" class="btn-primary btn-icon-tabla2">
                                                <i class="fas fa-plus"></i><span class="tiptext"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-for="(item4,index4) in ListaDetalleFile" :key="index4">
                                                
                                            <div class="input-group">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" @change="$uploadFileArrayCustom($event,ValidElementFile,item4,Array('Evidencia','NameFile'))" ref="evidenciaFile" accept="application/pdf" id="EvidenciaFile" name="EvidenciaFile[]">
                                                    <label for="EvidenciaFile" class="custom-file-label">{{item4.NameFile}}</label>
                                                </div>
                                                <div class="input-group-append ml-2">
                                                    <button type="button" @click="EliminarFile(index4);" class="btn btn-sm btn-danger">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </fieldset>
                    </div>
                
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    import moment         from 'moment';
    import StaticComboBox from '@/helpers/StaticComboBox.js';
    import Configs        from '@/helpers/VarConfig.js';

    export default {
        name:  "FormTickets",
        props: ['poBtnSave','mode'],
        components:{
            moment
        },
        data() {
            return {
                pFechaActual:       "0000-00-00",
                RutaEvid:           '', 
                Emit:               this.poBtnSave.EmitSeccion,
                ListaDetalle:       [],
                ListaDetalleFile:   [],
                SelectDepartamento: [],
                errorvalidacion:    [],
                TipoTicket:         StaticComboBox.TipoTicket,
                TipoPrioridad:      StaticComboBox.TipoPrioridad,
                MotivoSolicitud:    StaticComboBox.MotivoSolicitud,
                Departamento:       StaticComboBox.Departamento,
                ValidElement:       Configs.validImage2m,
                ValidElementFile:   Configs.validFile5m,
				ObjUsuario:         {},
                editorOption: {
                    placeholder: 'Escribir Mensaje',
                    theme:       'snow',
                    modules: {
                        toolbar: {
                            container: Configs.configTolbarOptions, // opciones de barra de herramientas
                        }
                    }
                },
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objTickets:{
                    IdTicket:          0,
                    IdUsuario:         '',
                    IdEmpresa:         '',
                    IdSucursal:        '',
                    Nombre:            '',
                    Correo:            '',
                    Folio:             '',
                    Asunto:            '',
                    TipoPrioridad:     '',
                    Telefono:          '',
                    TipoTicket:        '',
                    Descripcion:       '',
                    Recepcion:         '',
                    Cotizacion:        '',
                    OrdenTrabajo:      '',
                    OrdenCompra:       '',
                    CalibracionExt:    '',
                    Requisicion:       '',
                    Otro:              '',
                    MotivoSolicitud:   '',
                    Departamento:      '',
                    NuevoDepartamento: '',
                    Estatus:           '',
                    FechaActual:       new Date(),
                },
            }
        },
        methods:  {            
			Session() {
				this.ObjUsuario = JSON.parse(sessionStorage.getItem("user"));
			},
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0; 
                this.poBtnSave.DisableBtn = true;

                let formData = new FormData();
                formData.set('IdTicket',        this.objTickets.IdTicket);
                formData.set('IdUsuario',       this.ObjUsuario.IdUsuario);
                formData.set('IdEmpresa',       this.ObjUsuario.IdEmpresa);
                formData.set('IdSucursal',      this.ObjUsuario.IdSucursal);
                formData.set('Nombre',          this.ObjUsuario.Nombre);
                formData.set('Correo',          this.objTickets.Correo);
                formData.set('Folio',           this.objTickets.Folio);
                formData.set('Asunto',          this.objTickets.Asunto);
                formData.set('TipoPrioridad',   this.objTickets.TipoPrioridad);
                formData.set('Telefono',        this.objTickets.Telefono);
                formData.set('TipoTicket',      this.objTickets.TipoTicket);
                formData.set('Descripcion',     this.objTickets.Descripcion);
                formData.set('Recepcion',       this.objTickets.Recepcion);
                formData.set('Cotizacion',      this.objTickets.Cotizacion);
                formData.set('OrdenTrabajo',    this.objTickets.OrdenTrabajo);
                formData.set('OrdenCompra',     this.objTickets.OrdenCompra);
                formData.set('CalibracionExt',  this.objTickets.CalibracionExt);
                formData.set('Requisicion',     this.objTickets.Requisicion);
                formData.set('Otro',            this.objTickets.Otro);
                formData.set('MotivoSolicitud', this.objTickets.MotivoSolicitud);
                formData.set('Departamento',    this.SelectDepartamento);
                formData.set('NuevoDepartamento', this.objTickets.NuevoDepartamento);
                formData.set('Estatus',         this.objTickets.Estatus);

                let files3 = document.getElementsByName('file3[]');

                for(var i=0;i<this.ListaDetalle.length;i++)
                {
                    let file = (files3[i])?files3[i].files[0]:'';
                    // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA IMAGEN COMO CAMPO OBLIGATORIO
                    if(this.ListaDetalle[i].Evidencia =='' && file===undefined && this.ListaDetalle[i].IdTicketEvidencia == 0)
                    {
                        this.$toast.warning('La fotografía '+(i+1)+' de la evidencia debe ser agregada');
                        return false;
                    }
                    else
                    {
                        if(file!=undefined){
                            formData.append('FileEvidencia[]',file);
                        }
                    }
                }

                let fileinput = document.getElementsByName('EvidenciaFile[]');

                for(var i=0;i<this.ListaDetalleFile.length;i++)
                {
                    let file = (fileinput[i])?fileinput[i].files[0]:'';
                    // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA UN ARCHIVO COMO CAMPO OBLIGATORIO
                    if(this.ListaDetalleFile[i].Evidencia =='' && file===undefined && this.ListaDetalleFile[i].IdTicketEvidencia == 0)
                    {
                        this.$toast.warning('La archivo '+(i+1)+' de la evidencia pdf debe ser agregado');
                        return false;
                    }
                    else
                    {
                        if(file!=undefined){
                            formData.append('FileEvidenciaArch[]',file);
                        }
                    }
                }

                this.$http.post('tickets', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;  
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;
                
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            Recuperar() {
                this.$http.get("tickets/"+this.objTickets.IdTicket ).then( (res) => {
                    this.objTickets = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar() {
                this.objTickets = {                    
                    IdTicket:          0,
                    IdUsuario:         '',
                    IdEmpresa:         '',
                    IdSucursal:        '',
                    Nombre:            this.ObjUsuario.Nombre,
                    Correo:            this.ObjUsuario.Correo,
                    Folio:             '',
                    Asunto:            '',
                    TipoPrioridad:     '',
                    Telefono:          '',
                    TipoTicket:        '',
                    Descripcion:       '',
                    Recepcion:         '',
                    Cotizacion:        '',
                    OrdenTrabajo:      '',
                    OrdenCompra:       '',
                    CalibracionExt:    '',
                    Requisicion:       '',
                    Otro:              '',
                    MotivoSolicitud:   '',
                    Departamento:      '',
                    NuevoDepartamento: '',
                    Estatus:           '',
                    FechaActual:       moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                };
                this.errorvalidacion = [];
            },
            AgregarItem() {
                this.ListaDetalle.push({
                    IdTicketEvidencia: 0,
                    IdTicket: 0,
                    Evidencia: '',
                    TipoEvidencia: 'Imagen',
                    Descripcion: '',
                    ImagenTmp: '',
                    NameFile: this.ValidElement.NameFile
                });
            },
            Eliminar(index){
                this.$swal(Configs.configEliminarItem).then((result) => {

                    if(result.value) {
                        this.$swal(Configs.configEliminarConfirm);
                        this.ListaDetalle.splice(index, 1);
                    }
                });
            },
            AgregarItemFile() {
                this.ListaDetalleFile.push({
                    IdTicketEvidencia: 0,
                    IdTicket: 0,
                    Evidencia: '',
                    TipoEvidencia: 'Archivo',
                    Descripcion: '',
                    NameFile: this.ValidElementFile.NameFile
                });
            },
            EliminarFile(index){
                this.$swal(Configs.configEliminarItem).then((result) => {

                    if(result.value) {
                        this.$swal(Configs.configEliminarConfirm);
                        this.ListaDetalleFile.splice(index, 1);
                    }
                });
            },
        },
        created() {
            this.poBtnSave.toast = 0;
			this.Session();

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(Id)=> {

                this.ConfigLoad.ShowLoader = true;    
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,() => {
                    this.Guardar();
                });

                this.Limpiar();
                    
                if(Id!='') {
                    this.objTickets.IdTicket = Id;
                    this.Recuperar();
                } else {
                    this.ConfigLoad.ShowLoader = false;
                }           
            });
        },
    }
</script>