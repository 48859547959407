import Ticket     from "@/views/catalogos/ticket/List.vue";
import viewticket from "@/views/modulos/tickets/viewerTicket";

const VarRutasTicket  = [
    {
        path: '/Ticket',
        name: 'ticket',
        component: Ticket,
        props: true
    },
    {
        path: '/viewticket',
        name: 'viewticket',
        component: viewticket,
        props: true
    },

];

export default {
    VarRutasTicket,
}
