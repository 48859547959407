<template>
    <div class="mode p-2">
        <label class="label">
            <div class="form-inline">   
                <div class="label-text mx-4 mr-5">
                    <span v-if="mode === 'dark'"><i class="fal fa-sun"></i> Light mode </span>
                    <span v-else> <i class="fal fa-moon"></i> Dark mode</span>
                </div> 
                <div class="toggle">
                    <input class="toggle-state" :checked="(mode === 'dark') ? 'checked' : false"  @change="$emit('toggle')" type="checkbox" name="check" value="check" />
                    <div class="toggle-inner">
                        <div class="indicator"></div>
                    </div>
                    <div class="active-bg"></div>
                </div>           
            </div>
        </label>
    </div>
    
</template>

<script>
    export default {
        props: ['mode']
    }
</script>

