<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
            
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <label for="Nombre">Nombre</label>
                                    <input type="text" v-model="objDepartamento.Nombre" maxlength="250" class="form-control" placeholder="Ingresar Nombre" />
                                    <label><CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*'+errorvalidacion.Nombre[0]"></CValidation></label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

    export default {
        name:  "FormDepartamento",
        props: ['poBtnSave'],
        components:{ },
        data() {
            return {
                errorvalidacion: [],
                Emit:            this.poBtnSave.EmitSeccion,
                ObjUsuario:      {},
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objDepartamento:{
                    IdDepartamento: 0,
                    IdEmpresa:      0,
                    IdSucursal:     0,
                    Nombre:         '',
                },
            }
        },
        methods: {          
			Session() {
				this.ObjUsuario = JSON.parse(sessionStorage.getItem("user"));
			},
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0; 
                this.poBtnSave.DisableBtn = true;

                let formData = new FormData();
                formData.set('IdDepartamento',   this.objDepartamento.IdDepartamento);
                formData.set('IdEmpresa',  this.ObjUsuario.IdEmpresa);
                formData.set('IdSucursal', this.ObjUsuario.IdSucursal);
                formData.set('Nombre',     this.objDepartamento.Nombre);

                if(this.objDepartamento.IdDepartamento == 0) {
                    this.$http.post('departamentos', formData,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then((res)=>{                 
                        this.EjecutaConExito(res);
                    }).catch(err=>{   
                        this.EjecutaConError(err);
                    });
                } else {
                    this.$http.put('departamentos/'+this.objDepartamento.IdDepartamento, this.objDepartamento).then((res)=>{                 
                        this.EjecutaConExito(res);
                    }).catch(err=>{   
                        this.EjecutaConError(err);
                    });
                }
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;  
                this.poBtnSave.toast = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('List_'+this.Emit);
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;
                
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }

            },
            Recuperar() {
                this.$http.get("departamentos/"+this.objDepartamento.IdDepartamento).then( (res) => {
                    this.objDepartamento = res.data.data;
                }).finally(()=>{
                    this.ConfigLoad.ShowLoader = false;
                });
            },
            Limpiar() {

                this.objDepartamento = {
                    IdDepartamento: 0,
                    IdEmpresa:      this.ObjUsuario.IdEmpresa,
                    IdSucursal:     this.ObjUsuario.IdSucursal,
                    Nombre:         '',
                };

                this.errorvalidacion = [];
            },
        },
        created() {
            this.poBtnSave.toast = 0;
			this.Session();

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(Id)=> {

                this.ConfigLoad.ShowLoader = true;    
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,()=>
                {
                    this.Guardar();
                });
                this.Limpiar();
                    
                if(Id!='')  {
                    this.objDepartamento.IdDepartamento = Id;
                    this.Recuperar();
                } else {
                    this.ConfigLoad.ShowLoader = false;
                }

            });
        },
    }

</script>