<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="header">
                <th class="td-sm"></th>
                <th>#</th>

                <th>Marca</th>
                <th>Serie</th>
                <th>Departamento</th>
                <th>Nombre Equipo</th>
                <th class="text-center">Estatus</th>

                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{(index+1)}}</td>

                    <td>{{lista.Marca}}</td>
                    <td>{{lista.NoSerie}}</td>
                    <td>{{lista.departamento.Nombre}}</td>
                    <td>{{lista.NombreEquipoRed}}</td>

                    <td class="text-center">
                        <span v-if="lista.Estatus == 'Funcionando'" class="badge badge-success" style="width: 84px;">Funcionando</span>
                        <span v-else-if="lista.Estatus == 'Observacion'" class="badge badge-warning" style="width: 84px;">Observacion</span>
                        <span v-else-if="lista.Estatus == 'Falla'" class="badge badge-danger" style="width: 84px;">Falla</span>
                        <span v-else-if="lista.Estatus == 'Inactivo'" class="badge badge-secondary" style="width: 84px;">Inactivo</span>
                    </td>

                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdEquipo" :pEmitSeccion="ConfigList.EmitSeccion">
                            <template slot="btnaccion">
                                <button type="button" @click="verMantenimientos(lista)" v-b-tooltip.hover.Top title="Mantenimientos" class="btn btn-warning btn-icon ml-1">
                                    <i class="far fa-tools"></i>
                                </button>

                                <button type="button" @click="verLicencias(lista)" v-b-tooltip.hover.Top title="Licencias Software" class="btn btn-warning btn-icon ml-1">
                                    <i class="fas fa-file-certificate"></i>
                                </button>
                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="7"></CSinRegistros>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
            <template slot="Form">
                <Form :poBtnSave="oBtnSave" :poSucursal="ObjSucursal"></Form>
            </template>
        </CModal>

    </div>
</template>

<script>

import Configs    from '../../../helpers/VarConfig';
import CList from "../../../components/CList.vue";
import CSinRegistros from "../../../components/CSinRegistros.vue";
import CBtnAccion from "../../../components/CBtnAccion.vue";
import CModal from "../../../components/CModal.vue";
import Form from './Form.vue';

const EmitEjecuta = 'seccionEquiposSuc';


export default {
    name: 'ListaEquiposSucursal',
    props:['pObjSucursal'],
    components: {
        Form,
        CModal,
        CSinRegistros,
        CBtnAccion,
        CList
    },
    data() {
        return {
            ConfigList:{
                Title: 'Listado de Equipos',
                TitleFirst: 'Empresa',
                IsModal: true,
                ShowLoader: true,
                BtnReturnShow: true,
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            ConfigModal:{
                ModalTitle:  "Formulario Equipos",
                ModalNameId: 'ModalForm',
                EmitSeccion:  EmitEjecuta,
                ModalSize: 'lg'
            },
            oBtnSave: {
                toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },

            segurity:       {},
            obj:            {},
            ListaArrayRows: [],
            ListaHeader:    [],
            ObjSucursal:      {}
        }
    },
    methods: {
        async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('equipos', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                    IdSucursal:  this.ObjSucursal.IdSucursal,
                }
            }).then( (res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },
        Eliminar(Id)
        {
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value)
                {
                    this.$http.delete('usuariossucursal/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },
        Regresar()
        {
            this.$router.push({name:'empresa',params:{Id:this.ObjSucursal.IdEmpresa}});
        },

        verMantenimientos(obj) {
            this.$router.push({name:'mantenimientos',params:{pObjEquipo:obj, pObjSucursal:this.ObjSucursal}});
        },

        verLicencias(obj){
            this.$router.push({name:'equipolicencias',params:{pObjEquipo:obj}});
        }
    },
    created()
    {
        if(this.pObjSucursal != undefined){
            sessionStorage.setItem('oSucursal',JSON.stringify(this.pObjSucursal));
        }

        this.ObjSucursal = JSON.parse(sessionStorage.getItem('oSucursal'));
        this.ConfigList.Title = this.ConfigList.Title+': '+this.ObjSucursal.Nombre;

        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
        this.bus.$off('EmitReturn');
    },
    mounted()
    {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });

        this.bus.$on('EmitReturn',()=>
        {
            this.Regresar();
        });
    },
}

</script>
