<template>
    <div>
        <CList :pConfigList="ConfigList">
            <template slot="bodyForm">
                <CLoader :pConfigLoad="ConfigLoad">
                    <template slot="BodyFormLoad">
                        <section class="container-fluid">
                            <div class="row justify-content-end">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 scrollp">
                                    <div class="card border">
                                        <div class="card-header">
                                            <i class="fas fa-ticket"></i> <strong>Información del Ticket</strong>
                                        </div>

                                        <ul class="list-group list-group-flush ver" id="info" style="display:block;">
                                            <li class="list-group-item">
                                                <span class="small letra-verde">Tipo de Asistencia</span><br> {{oTicket.TipoTicket}}
                                            </li>

                                            <li class="list-group-item">
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <span class="small letra-verde">Prioridad</span><br>
                                                        <template v-if="oTicket.TipoPrioridad == 3"> <b-badge pill variant="danger" >Alta</b-badge> </template>
                                                        <template v-else-if="oTicket.TipoPrioridad == 2"> <b-badge pill variant="warning" >Media</b-badge> </template>
                                                        <template v-else-if="oTicket.TipoPrioridad == 1"> <b-badge  pill variant="info" >Baja</b-badge> </template>
                                                    </div>

                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="border-left:1px solid #ccc">
                                                        <span class="small letra-verde">Estatus</span><br>
                                                        <span v-if="oTicket.Estatus == 'Pendiente'" class="badge badge-secondary" style="width: 80px;">Pendiente</span>
                                                        <span v-else-if="oTicket.Estatus == 'Proceso'" class="badge badge-primary" style="width: 80px;">En Proceso</span>
                                                        <span v-else-if="oTicket.Estatus == 'Atendido'" class="badge badge-warning" style="width: 80px;">Atendido</span>
                                                        <span v-else-if="oTicket.Estatus == 'Cerrado'" class="badge badge-danger" style="width: 80px;">Cerrado</span>
                                                    </div>
                                                </div>
                                            </li>

                                            <li class="list-group-item list-group-item-normalizado">
                                                <div class="form-row">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <span class="small letra-verde">Enviado</span><br>
                                                        <span style="font-size:12px">{{$getCleanDate(oTicket.created_at,true)}}</span>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="border-left:1px solid #ccc">
                                                        <span class="small letra-verde">Última Actualización</span><br>
                                                        <span style="font-size:12px">{{$getCleanDate(oTicket.updated_at,true)}}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                        <div class="card-footer text-center">
                                            <div class="form-row justify-content-center">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" v-if="oTicket.Estatus === 'Pendiente'">
                                                    <template v-if="UserSesion.IdPerfil == 1">
                                                        <button type="button" @click="AtenderSolicitud();" class="btn btn-success btn-sm w-btn mr-2" >
                                                            Atender Ticket
                                                        </button>
                                                    </template>
                                                </div>

                                                <template v-else-if="oTicket.Estatus !== 'Cerrado'">
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1" v-if="Visible">
                                                        <template >
                                                            <button type="button" @click="ResponderTicket();" class="btn btn-success btn-sm w-btn mr-2">
                                                               &nbsp;&nbsp; Responder &nbsp;&nbsp;
                                                            </button>
                                                        </template>
                                                    </div>
                                                    <!--
                                                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1" v-if="Visible">
                                                        <button type="button" @click="ResponderTicket();" class="btn btn-primary btn-sm w-btn mr-2">
                                                            Notificar Ticket
                                                        </button>
                                                    </div>-->

                                                    <template v-if="UserSesion.IdPerfil == 1">
                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1">
                                                            <button v-if="oTicket.Estatus !== 'Atendido'" type="button" @click="NotificarTicket();" class="btn btn-primary w-btn btn-sm mr-2">Notifica Ticket</button>
                                                        </div>

                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1">
                                                            <button v-if="oTicket.Estatus !== 'Atendido'" type="button" @click="AtendidoTicket();" class="btn btn-warning w-btn btn-sm mr-2">Atender Ticket</button>
                                                        </div>

                                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-1">
                                                            <button type="button" @click="CerrarTicket();" class="btn btn-danger w-btn btn-sm mr-2">&nbsp;Cerrar Ticket&nbsp;</button>
                                                        </div>
                                                    </template>
                                                </template>
                                            </div>
                                            <!--
                                            <template v-if="oTicket.Estatus === 'Pendiente'">
                                                <template v-if="UserSesion.IdPerfil == 1">
                                                    <button type="button" @click="AtenderSolicitud();" class="btn btn-success btn-sm w-btn mr-2" >
                                                        Atender Ticket
                                                    </button>
                                                </template>
                                            </template>

                                            <template v-else-if="oTicket.Estatus !== 'Cerrado'">
                                                <template v-if="Visible">
                                                    <button type="button" @click="ResponderTicket();" class="btn btn-success btn-sm w-btn mr-2">
                                                        Responder
                                                    </button>
                                                </template>
                                                <template v-if="UserSesion.IdPerfil == 1">
                                                    <button v-if="oTicket.Estatus !== 'Atendido'" type="button" @click="AtendidoTicket();" class="btn btn-warning w-btn btn-sm mr-2">Atender Ticket</button>
                                                    <button type="button" @click="CerrarTicket();" class="btn btn-danger w-btn btn-sm">Cerrar Ticket</button>
                                                </template>
                                            </template>
                                            -->

                                        </div>
                                    </div>

                                    <div class="card border mt-3">
                                        <div class="card-header">
                                            <i class="fas fa-ticket"></i> <strong>Detalle del Problema</strong>
                                        </div>
                                        <ul class="list-group list-group-flush ver" id="info" style="display:block;">

                                            <li class="list-group-item">
                                                <span class="small letra-verde">Motivo Solicitud</span><br>{{CMBFiltrado(oTicket.MotivoSolicitud,CmbDinamico.MotivoSolicitud)}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.Cotizacion != '' && oTicket.Cotizacion!=null)">
                                                <span class="small letra-verde">Cotización</span><br>{{oTicket.Cotizacion}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.Recepcion != '' && oTicket.Recepcion!=null)" >
                                                <span class="small letra-verde">Recepción</span><br>{{oTicket.Recepcion}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.OrdenTrabajo != '' && oTicket.OrdenTrabajo!=null)" >
                                                <span class="small letra-verde">Orden</span><br>{{oTicket.OrdenTrabajo}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.CalibracionExt != '' && oTicket.CalibracionExt!=null)" >
                                                <span class="small letra-verde">Calibracion Externa</span><br>{{oTicket.CalibracionExt}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.Requisicion != '' && oTicket.Requisicion!=null)" >
                                                <span class="small letra-verde">Requisicion</span><br>{{oTicket.Requisicion}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.OrdenCompra != '' && oTicket.OrdenCompra!=null)" >
                                                <span class="small letra-verde">Orden de Compra</span><br>{{oTicket.OrdenCompra}}
                                            </li>
                                            <li class="list-group-item" v-if="(oTicket.Otro != '' && oTicket.Otro!=null)" >
                                                <span class="small letra-verde">Otro</span><br>{{oTicket.Otro}}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="card border mt-3" v-if="this.UserSesion.IdPerfil === 1">
                                        <div class="card-header">
                                            <i class="fas fa-ticket"></i> <strong>Datos de contacto</strong>
                                        </div>
                                        <ul class="list-group list-group-flush ver" id="info" style="display:block;">

                                            <li class="list-group-item">
                                                <span class="small letra-verde">Nombre del Usuario</span><br>{{oTicket.Usuario.NombreCompleto}}
                                            </li>

                                            <li class="list-group-item">
                                                <span class="small letra-verde">Correo Eléctronico</span><br>{{oTicket.Usuario.Correo}}
                                            </li>

                                            <li class="list-group-item">
                                                <span class="small letra-verde">Teléfono</span><br>
                                                    {{oTicket.Telefono}}
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                                <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 scrollp">
                                    <div v-if="oTicket.Estatus === 'Cerrado'" class="alert alert-warning text-center" role="alert">
                                        Este ticket se ha cerrado y no se puede volver a abrir
                                    </div>
                                    <CardRespuesta  :pDetalle="oTicket.Respuestas" :pRutaFiles="RutaFileResp"></CardRespuesta><!-- v-if="oTicket.Estatus !== 'Cerrado'" -->

                                    <div class="card mb-3" >
                                        <div class="card-header">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <p class="mb-n1">
                                                        <template v-if="oTicket.Usuario.Genero == 'Femenino'">
                                                            <img src="@/assets/style/image/002-Mujer.png" alt="" width="27" height="27" class="rounded-circle">
                                                        </template>
                                                        <template v-else>
                                                            <img src="@/assets/style/image/001-Hombre.png" alt="" width="27" height="27" class="rounded-circle">
                                                        </template>
                                                        {{oTicket.Usuario.NombreCompleto}}
                                                    </p>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                                                    <p class="mb-n1">{{$getCleanDate(oTicket.created_at,true)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row mb-5">
                                                <div class="col-12">
                                                    <div v-html="oTicket.Descripcion"></div>
                                                </div>
                                                <br>
                                            </div>

                                            <div class="form-group row" v-if="oTicket.Evidencias.length>0">
                                                <div class="col-12">
                                                    <h6>Evidencias Fotograficas</h6>
                                                </div>
                                            </div>

                                            <div class="row mt-2 mb-3" v-if="oTicket.Evidencias.length>0">
                                                <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" v-for="(img,index2) in oTicket.Evidencias" :key="index2">
                                                    <img :src="RutaFile+img.Evidencia" class="img-thumbnail img-fluid" alt="...">
                                                    <a class="btn-ver" @click="verImagen(img)">
                                                        <i class="fas fa-eye"></i>
                                                    </a>
                                                </div>
                                            </div>

                                            <div class="form-group row" v-if="oTicket.EvidenciasFile.length>0">
                                                <div class="col-12">
                                                    <h6>Evidencias Archivos</h6>
                                                </div>
                                            </div>

                                            <template v-if="oTicket.EvidenciasFile.length>0">
                                                <div class="form-group row" v-for="(img,index2) in oTicket.EvidenciasFile" :key="index2">
                                                    <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                        <button type="button" @click="verArchivo(img)" class="btn btn-sm btn-primary">
                                                            <i class="fa fa-eye"></i> Ver PDF
                                                        </button>
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </template>
                </CLoader>
            </template>
        </CList>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<FormRespuestas :poBtnSave="oBtnSave"></FormRespuestas>
			</template>
		</CModal>

    </div>
</template>

<script>

import moment         from "moment-timezone";
import FormRespuestas from './components/FormRespuestas.vue';
import CardRespuesta  from './components/CardRespuesta.vue';
import StaticComboBox from '@/helpers/StaticComboBox.js';
import Template from '../../template/Template.vue';

const EmitEjecuta = "seccionVerTicket";

export default {
    name: "ResponderTicket",
    props:['Id','Origen'],
    components: {
        FormRespuestas,
        CardRespuesta,
        Template,
    },
    data() {
        return {
            ConfigList:{
                Title: 'Ticket:',
                ShowLoader: false,
                IsModal: false,
                BtnReturnShow: true,
                ShowSearch: false,
                ShowPaginador: false,
                ShowEntradas: false,
                BtnNewShow: false,
                TypeBody: 'Form',
                ShowTitleFirst: false,
                EmitSeccion: EmitEjecuta,
                SinFiltros: true,
            },
            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },
            ConfigModal:{
                ModalTitle:  "Responder",
                ModalNameId: 'ModalForm',
                EmitSeccion:  EmitEjecuta,
                ModalSize: 'lg'
            },
            oBtnSave: {
                toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },
            backToPage: '',
            oTicket:{
                IdTicket: 0,
                IdUsuario: 0,
                IdEmpresa: 0,
                IdSucursal: 0,
                Correo: '',
                Folio: '',
                Asunto: '',
                TipoPrioridad: '',
                Telefono: '',
                TipoTicket: '',
                Descripcion: '',
                Recepcion: '',
                Cotizacion: '',
                OrdenTrabajo: '',
                OrdenCompra: '',
                CalibracionExt: '',
                Requisicion: '',
                Otro: '',
                MotivoSolicitud: '',
                Departamento: '',
                Estatus: '',
                Usuario:{},
                Evidencias: [],
                EvidenciasFile: [],
                Respuestas: [],
            },
            BndForm: false,
            RutaFile: '',
            RutaFileResp: '',
            CmbDinamico: StaticComboBox,
            UserSesion: {},
            IdTicket: 0,
            OrigenSit: '',
            Visible: false,
        }
    },
    methods: {
        recuperar() {
            this.$http.get(
                "getcompleteticket/"+this.oTicket.IdTicket
            ).then((res) => {
                this.oTicket        = res.data.data;
                this.RutaFile       = res.data.RutaFileEvi;
                this.RutaFileResp   = res.data.RutaFileEviResp;

                this.ConfigList.Title = 'Ticket: #'+this.oTicket.Folio+' '+this.limitCharacters(this.oTicket.Asunto);
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
                this.ValidaVisibilidad();
            });
        },
        limpiar(){
            this.oTicket = {
                IdTicket: 0,
                IdUsuario: 0,
                IdEmpresa: 0,
                IdSucursal: 0,
                Correo: '',
                Folio: '',
                Asunto: '',
                TipoPrioridad: '',
                Telefono: '',
                TipoTicket: '',
                Descripcion: '',
                Recepcion: '',
                Cotizacion: '',
                OrdenTrabajo: '',
                OrdenCompra: '',
                CalibracionExt: '',
                Requisicion: '',
                Otro: '',
                MotivoSolicitud: '',
                Departamento: '',
                Estatus: ''
            }
        },
        Regresar() {
            this.$router.push({name:this.backToPage,params:{}});
        },

        limitCharacters(text){
            if(text !== ''){
                let points = text.length > 35 ? '...' : '';
                return text.substr(0,35)+points;
            }else {
                return ''
            }

        },
        AtenderSolicitud()
        {
            this.$http.get(
                'atenderticket',{
                params:{
                    IdTicket: this.oTicket.IdTicket,
                }
            }).then((res) => {
                this.$toast.success('El Ticket ha sido asignado.');
                this.recuperar();
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        ResponderTicket()
        {
            this.bus.$emit('NewModal_'+EmitEjecuta,this.oTicket);
        },
        AtendidoTicket()
        {
            this.$swal(
                {
                    title: '<h2 style="font-size:25px; font-weight: 700;">¿Desea marcar como atendido el ticket?</h2>',
                    text: 'Esta acción no se podra revertir ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor: '#0096ED',
                    cancelButtonColor: '#b92e27',
                }
            ).then((result) => {
                if(result.value) {
                    this.$http.post('revisionticket',this.oTicket)
                    .then((res)=>{
                        this.$toast.success('Ticket en espera de validación.','','');
                        this.recuperar();
                    }).catch(err=>{
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        },
        CerrarTicket()
        {
            this.$swal(
                {
                    title: '<h2 style="font-size:25px; font-weight: 700;">¿Desea cerrar el ticket?</h2>',
                    text: 'Esta acción no se podra revertir ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor: '#0096ED',
                    cancelButtonColor: '#b92e27',
                }
            ).then((result) => {
                if(result.value)
                {
                    this.$http.get(
                        'cerrarticket',{
                        params:{
                            IdTicket: this.oTicket.IdTicket,
                        }
                    }).then((res) => {
                        this.$toast.success('El Soporte ha concluido.');
                        this.recuperar();
                    }).catch( err => {
                        this.$toast.error(err.response.data.message);
                    }).finally(()=>{
                        this.ConfigLoad.ShowLoader = false;
                    });
                }
            });
        },
        CMBFiltrado(buscar,combo) {
            let aBuscar  = buscar;
            let devuelve = '';
            let arr = combo.filter(function(vl){

                if(aBuscar!=''){
                    if(aBuscar == vl.id){
                        return vl;
                    } else {
                        return '';
                    }
                }
            });

            if(arr[0]){
                devuelve = arr[0].label;
            }

            return devuelve;
        },
        ValidaVisibilidad()
        {
            let Ver = false;
            if(this.UserSesion.IdPerfil == 1){
                Ver = true;
            }
            else if(this.UserSesion.IdPerfil!=1 && this.oTicket.Respuestas.length>0 && (this.UserSesion.IdUsuario == this.oTicket.IdUsuario)){
                Ver = true;
            }

            this.Visible = Ver;
        },
        verImagen(item) {
            window.open(this.RutaFile+item.Evidencia,'_blank');
        },
        timer() {
            this.IntervalTime = setInterval(
                function() {
                    this.recuperar("");
                }.bind(this),
                360000
            );
        },
        verArchivo(item){
            window.open(this.RutaFile+item.Evidencia,'_blank');
        },
        NotificarTicket()
        {
            this.$swal(
                {
                    title: '<h2 style="font-size:25px; font-weight: 700;">¿Desea Solicitar una respuesta del cliente?</h2>',
                    text: 'Esta acción no se podra revertir ',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continuar',
                    cancelButtonText: 'Cancelar',
                    showCloseButton: true,
                    showLoaderOnConfirm: true,
                    confirmButtonColor: '#0096ED',
                    cancelButtonColor: '#b92e27',
                }
            ).then((result) => {
                if(result.value) {
                    this.$http.post('notificacionticket',this.oTicket)
                    .then((res)=>{
                        this.$toast.success('Se ha solicitado respuesta al cliente.','','');
                        this.recuperar();
                    }).catch(err=>{
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        }
    },
    created() {
        this.bus.$off('EmitReturn');
        this.bus.$off('Recuperar');

        let sess = JSON.parse(sessionStorage.getItem('user'));
        this.UserSesion = sess;
    },
    mounted() {
        //this.limpiar();
        this.timer();

        if(this.Origen !== undefined) {
            sessionStorage.setItem('sitio',this.Origen);
        }

        this.OrigenSit = sessionStorage.getItem('sitio');

        if(this.OrigenSit!=''){
            const destDefault      = 'inicio';
            const posiblesDestinos = {
                'Dash':  'dashboardtickes',
                'Lista': 'ticket'
            }

            this.backToPage = posiblesDestinos[this.OrigenSit] || destDefault;
        }

        if(this.Id!=undefined){
            sessionStorage.setItem('IdTicket',this.Id);
        }

        this.IdTicket = sessionStorage.getItem('IdTicket');

        if(this.IdTicket>0){
            this.oTicket.IdTicket = this.IdTicket;
            this.recuperar();
        }

        this.bus.$on('EmitReturn',()=>
        {
            this.Regresar();
        });

        this.bus.$on('Recuperar',()=>
        {
            this.recuperar();
        });
    }
}
</script>
<style>
.scrollp{
    overflow-y: auto;
    max-height: 500px;
}
</style>