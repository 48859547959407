<template>
    <CLoader :p-config-load="ConfigLoad">
        <template slot="BodyFormLoad">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">

                            <div class="form-row">
                                <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                    <div class="avatar-upload">
                                        <div class="avatar-edit">
                                            <input id="file"  @change="$uploadImagePreview($event,ValidElement,Array('Img','imagePreview'))"  ref="file"  type="file" name="myfile"  accept=".png, .jpg, .jpeg">
                                            <label for="file"></label>
                                        </div>
                                        <div class="avatar-preview">
                                            <div id="imagePreview" :style="'background-image: url('+RutaFile+Empresa.Imagen+');'" :src="RutaFile+Empresa.Imagen">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="NombreComercial">Nombre Comercial</label>
                                            <input  class="form-control" id="NombreComercial" v-model="Empresa.NombreComercial" type="text" placeholder="Ingrese Nombre Comercial"/>
                                            <CValidation v-if="this.errorvalidacion.NombreComercial" :Mensaje="'*' + errorvalidacion.NombreComercial[0]" />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-43">
                                            <label for="RazonSocial">Razón Social</label>
                                            <input class="form-control" id="RazonSocial" v-model="Empresa.RazonSocial" type="text" placeholder="Ingrese Razón Social"/>
                                            <CValidation v-if="this.errorvalidacion.RazonSocial" :Mensaje="'*' + errorvalidacion.RazonSocial[0]" />
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Rfc">RFC</label>
                                            <input class="form-control" id="Rfc" v-model="Empresa.Rfc" type="text" placeholder="Ingrese RFC"/>
                                            <CValidation v-if="this.errorvalidacion.Rfc" :Mensaje="'*' + errorvalidacion.Rfc[0]"/>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Calle">Calle</label>
                                            <input class="form-control" id="Calle" v-model="Empresa.Calle" type="text" placeholder="Ingrese Calle"/>
                                            <CValidation v-if="this.errorvalidacion.Calle" :Mensaje="'*' + errorvalidacion.Calle[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="NoExt">Número Exterior</label>
                                            <input class="form-control" id="NoExt" v-model="Empresa.NoExt" type="text" placeholder="Ingrese Número Exterior"/>
                                            <CValidation v-if="this.errorvalidacion.NoExt" :Mensaje="'*' + errorvalidacion.NoExt[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="NoInt">Número Interior</label>
                                            <input class="form-control" id="NoInt" v-model="Empresa.NoInt" type="text" placeholder="Ingrese Número Interior"/>
                                            <CValidation v-if="this.errorvalidacion.NoInt" :Mensaje="'*' + errorvalidacion.NoInt[0]"/>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Cp">Código Postal</label>
                                            <input class="form-control" id="Cp" v-model="Empresa.Cp" type="text" @input="$onlyNums($event,Empresa,'Cp')" placeholder="Ingrese Cp" maxlength="10"/>
                                            <CValidation v-if="this.errorvalidacion.Cp" :Mensaje="'*' + errorvalidacion.Cp[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Colonia">Colonia</label>
                                            <input class="form-control" id="Colonia" v-model="Empresa.Colonia" type="text" placeholder="Ingrese Colonia"/>
                                            <CValidation v-if="this.errorvalidacion.Colonia" :Mensaje="'*' + errorvalidacion.Colonia[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Referencias">Referencias</label>
                                            <textarea class="form-control" id="Referencias" v-model="Empresa.Referencias" placeholder="Ingrese Referencias" rows="1"/>
                                            <CValidation v-if="this.errorvalidacion.Referencias" :Mensaje="'*' + errorvalidacion.Referencias[0]"/>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Telefono">Telefono</label>
                                            <input class="form-control" id="Telefono" v-model="Empresa.Telefono" type="text" maxlength="10" @input="$onlyNums($event,Empresa,'Telefono')" placeholder="Ingrese Telefono"/>
                                            <CValidation v-if="this.errorvalidacion.Telefono" :Mensaje="'*' + errorvalidacion.Telefono[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Estado">Estado</label>
                                            <select id="Estado" class="form-control form-select" v-model="Empresa.IdEstado" @change="getMunicipios()">
                                                <option :value="0">--Seleccionar--</option>
                                                <option v-for="(item, index) in estados" :key="index" :value="item.IdEstado">
                                                    {{ item.Nombre }}
                                                </option>
                                            </select>
                                            <CValidation v-if="this.errorvalidacion.IdEstado" :Mensaje="'*' + errorvalidacion.IdEstado[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                            <label for="Municipio">Municipio</label>
                                            <select v-model="Empresa.IdMunicipio" id="Municipio" class="form-control form-select">
                                                <option :value="0">--Seleccionar--</option>
                                                <option v-for="(item, index) in municipios" :key="index" :value="item.IdMunicipio">
                                                    {{ item.Nombre }}
                                                </option>
                                            </select>
                                            <CValidation v-if="this.errorvalidacion.IdMunicipio" :Mensaje="'*' + errorvalidacion.IdMunicipio[0]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>

            </div>
        </template>
    </CLoader>
</template>

<script>
import CList from "../../../components/CList";
import CLoader from "../../../components/CLoader";
import CValidation from "../../../components/CValidation";
import CBtnSave from "../../../components/CBtnSave";
import varConfig from "../../../helpers/VarConfig";

export default {
    name: "FormDetalleEmpresa",
    props:['poBtnSave'],
    components:{
        CLoader,
        CList,
        CValidation,
        CBtnSave,
    },
    data() {
        return {
            ConfigLoad: {
                ShowLoader: false,
                ClassLoad: false,
            },
            Filtro: {
                Nombre: "",
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                Placeholder: "Buscar..",
            },
            Emit: this.poBtnSave.EmitSeccion,
            ValidElement: varConfig.validImage5m,

            Empresa:{
                IdEmpresa: 0,
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Referencias: '',
                IdEstado: '0',
                IdMunicipio: '0',
                Telefono: ''
            },

            errorvalidacion: [],

            estados:[],
            municipios:[],

            segurity: {},
            obj: {},
            ListaArrayRows: [],
            ListaHeader: [],
            RutaFile: '',
        }
    },
    methods :{
        /*
        * Listado de estados del combo
        */
        async recuperaEmpresa() {


            await this.$http.get('empresas/'+this.Empresa.IdEmpresa).then(res => {
                this.Empresa = res.data.data;
                this.RutaFile          = res.data.rutaFile;
                this.getEstados()

            }).catch(err =>{
                this.limpiar();
            }).finally(() => {
                this.ConfigLoad.ShowLoader = false;
            });
        },
        /*
        * Desvuelve al estado inicial el objeto Empresa
        */
        limpiar(){
            this.Empresa = {
                IdEmpresa: 0,
                NombreComercial: '',
                RazonSocial: '',
                Rfc: '',
                Calle: '',
                NoInt: '',
                NoExt: '',
                Colonia: '',
                Cp: '',
                Referencias: '',
                IdEstado: '0',
                IdMunicipio: '0',
                Telefono: ''
            };
        },
        /*
        * Listado de estados del combo
        */
        async getEstados() {
            await this.$http.get('estados').then(res => {
                this.estados = res.data.data;
            }).catch(err =>{
                this.estados = [];
            }).finally( () => {
                this.getMunicipios();
            });
        },

        /*
        * Listado de municipios del combo
        */
        getMunicipios() {
            if (parseInt(this.Empresa.IdEstado) > 0)
            {
                this.municipios = [];

                this.$http.get('municipios',{
                    params:{
                        IdEstado: this.Empresa.IdEstado
                    }
                }).then(res => {
                    this.municipios = res.data.data;

                }).catch(err =>{
                    this.municipios = [];
                });
            }else {
                this.municipios = [];
            }
        },
        /*
        * Save de empresa
        */
        UpdateEmpresa(){
            let formData = new FormData();
            formData.set('IdEmpresa',this.Empresa.IdEmpresa);
            formData.set('NombreComercial',this.Empresa.NombreComercial);
            formData.set('RazonSocial',this.Empresa.RazonSocial);
            formData.set('Rfc',this.Empresa.Rfc);
            formData.set('Calle',this.Empresa.Calle);
            formData.set('NoInt',this.Empresa.NoInt);
            formData.set('NoExt',this.Empresa.NoExt);
            formData.set('Colonia',this.Empresa.Colonia);
            formData.set('Cp',this.Empresa.Cp);
            formData.set('Referencias',this.Empresa.Referencias);
            formData.set('IdEstado',this.Empresa.IdEstado);
            formData.set('IdMunicipio',this.Empresa.IdMunicipio);
            formData.set('Telefono',this.Empresa.Telefono);
            formData.set('Imagen',this.Empresa.Imagen);

            let picture = this.$refs.file.files[0];
            formData.append('Imagen',picture);

            this.errorvalidacion = [];

            this.$http.post("empresasup", formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                },
            ).then((res) => {
                this.EjecutaConExito(res);
            }).catch((err) => {
                this.EjecutaConError(err);
            });
        },

        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;

            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
    },
    mounted() {

    },
    created() {

        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.UpdateEmpresa();
            });

            this.limpiar();

            if(Id!='')
            {
                this.Empresa.IdEmpresa = Id;
                this.recuperaEmpresa();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>

