<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
            
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">

                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 quillEditorcol">
                                    <label for="">Mensaje</label>
                                    <quill-editor v-model="objMensaje.Texto" ref="quillEditorAC" :options="editorOption" class="quillEditor"></quill-editor>
                                    
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <CValidation v-if="this.errorvalidacion.Texto" :Mensaje="'*' + errorvalidacion.Texto[0]"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

import Configs from '@/helpers/VarConfig.js';

export default {
    name:  "FormMensajeCorreo",
    props: ['poBtnSave'],
    components:{ },
    data() {
        return {
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad:  false,
            },
            objMensaje:{
                IdMensajeCorreo: 0,
                Texto: '',
            },
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            editorOption: {
                placeholder: 'Escribir Mensaje',
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: Configs.configTolbarOptions, // opciones de barra de herramientas
                    }
                }
            },
        }
    },
    methods: {          
        async Guardar() {
            this.errorvalidacion      = [];
            this.poBtnSave.toast      = 0; 
            this.poBtnSave.DisableBtn = true;

            let formData = new FormData();
            formData.set('IdMensajeCorreo', this.objMensaje.IdMensajeCorreo);
            formData.set('Texto', this.objMensaje.Texto);

            if(this.objMensaje.IdMensajeCorreo == 0) {
                this.$http.post('mensajeCorreo', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            } else {
                this.$http.put(
                    'mensajeCorreo/'+this.objMensaje.IdMensajeCorreo, 
                    this.objMensaje
                ).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            }
        },
        EjecutaConExito(res) {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err) {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            } else {
                this.$toast.error(err.response.data.message);
            }

        },
        Recuperar() {
            this.$http.get("mensajeCorreo/"+this.objMensaje.IdMensajeCorreo)
            .then( (res) => {
                this.objMensaje = res.data.data;
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar() {

            this.objMensaje = {
                IdMensajeCorreo: 0,
                Texto: '',
            };

            this.errorvalidacion = [];
        },
    },
    created() {
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=> {

            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn  = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
                
            if(Id!='')  {
                this.objMensaje.IdMensajeCorreo = Id;
                this.Recuperar();
            } else {
                this.ConfigLoad.ShowLoader = false;
            }

        });
    },
}

</script>