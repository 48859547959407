<template>
    <div>
        <input type="hidden" :value="validar">
        <template v-for="(item, index) in ListaDetalle">
            <div class="card mb-3" :key="index">

                <div class="card-header" v-bind:class="[item.UsuarioResp.IdPerfil === 1 ? 'bg-respuesta': '']">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <p class="mb-n1">
                                <template v-if="item.UsuarioResp.Genero == 'Femenino'">
                                    <img src="@/assets/style/image/002-Mujer.png" alt="" width="27" height="27" class="rounded-circle">
                                </template>
                                <template v-else>
                                    <img src="@/assets/style/image/001-Hombre.png" alt="" width="27" height="27" class="rounded-circle">
                                </template>

                                <template v-if="item.UsuarioResp.IdPerfil === 1">
                                    Soporte Técnico
                                </template>
                                <template v-else>
                                    {{item.UsuarioResp.NombreCompleto}}
                                </template>

                            </p>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                            <p class="mb-n1">{{$getCleanDate(item.created_at,true)}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-5">
                        <div class="col-12">
                            <div v-html="item.Descripcion"></div>
                        </div>
                    </div>

                    <div class="form-group row" v-if="item.EvidenciasResp.length>0">
                        <div class="col-12">
                            <h6>Evidencias Fotograficas</h6>
                        </div>
                    </div>

                    <div class="row mt-2 mb-3" v-if="item.EvidenciasResp.length>0">
                        <template v-for="(img,index2) in item.EvidenciasResp">
                            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" :key="index2">
                                <img :src="RutaFiles+img.Evidencia" class="img-thumbnail img-fluid" alt="...">
                                <a class="btn-ver" @click="verImagenEvidencia(img)">
                                    <i class="fas fa-eye"></i>
                                </a>
                            </div>
                        </template>
                    </div>

                    <div class="form-group row" v-if="item.EvidenciasRespFile.length>0">
                        <div class="col-12">
                            <h6>Evidencias Archivos</h6>
                        </div>
                    </div>

                    <template v-if="item.EvidenciasRespFile.length>0">
                        <template v-for="(img,index2) in item.EvidenciasRespFile">
                            <div class="form-group row" :key="index2">
                                <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <button type="button" @click="verArchivoEvidencia(img)" class="btn btn-sm btn-primary">
                                        <i class="fa fa-eye"></i> Ver PDF
                                    </button>
                                </div>
                            </div>
                        </template>
                    </template>

                </div>
            </div>
        </template>
    </div>
</template>

<script>
import moment from "moment-timezone";

export default {
    name: "CardRespuesta",
    props: ['pDetalle','pRutaFiles'],
    components: {},

    data() {
        return {
            ListaDetalle: [],
            RutaFiles: '',
        }
    },
    methods: {
        verImagenEvidencia(item) {
            window.open(this.RutaFiles+item.Evidencia,'_blank');
        },
        verArchivoEvidencia(item){
            window.open(this.RutaFiles+item.Evidencia,'_blank');
        }
    },
    computed: {
        validar()
        {
            if(this.pDetalle != undefined){
                this.ListaDetalle = this.pDetalle;
            }

            if(this.pRutaFiles != undefined){
                this.RutaFiles = this.pRutaFiles;
            }
            return '';
        }
    },
    created() {

    }

}
</script>
