<template>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
            
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label for="item16">Usuario</label>
                                    <input type="text" name="item16" id="item16" class="form-control" placeholder="Nombre del Usuario" v-model="objUsuarioSuc.username" >
                                    <CValidation v-if="this.errorvalidacion.username" :Mensaje="'*'+errorvalidacion.username[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label for="item18">Contraseña</label>
                                    <input v-model="objUsuarioSuc.password" :type="type1" class="form-control" placeholder="Contraseña" name="item18" id="item18">
                                    <button @click="ToggleShow" class="button btn-password-formulario" type="button" id="button-addon2">
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password" :Mensaje="'*'+errorvalidacion.password[0]"/>
                                </div>
                            </div>

                            <div class="form-group form-row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <label for="item19">Confirmar Contraseña</label>
                                    <input v-model="objUsuarioSuc.password_confirmation" :type="type2" class="form-control" placeholder="Confirmar Contraseña" name="item19" id="item19">
                                    <button @click="ToggleShowConfirm" class="button btn-password-formulario" type="button" id="button-addon2">
                                        <i class="far icono-password" :class="{ 'fa-eye-slash': showPasswordConfirm, 'fa-eye': !showPasswordConfirm }"></i>
                                    </button>
                                    <CValidation v-if="this.errorvalidacion.password_confirmation" :Mensaje="'*'+errorvalidacion.password_confirmation[0]"/>
                                </div>
                            </div>
                        </div><!--fin col-12-->
                    </div>
                </div>
            </form>
        </template>
    </CLoader>
</template>

<script>

export default {
    name:       "FormPasswordUsuario",
    props:      ['poBtnSave'],
    components: { },
    data() {
        return {
            type1:               'password', 
            type2:               'password',  
            showPassword:        false,
            showPasswordConfirm: false,
            password:            null,
            ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objUsuarioSuc:{
                IdUsuario: 0,
                IdEmpresa: 0,
                IdSucursal: 0,
                IdPuesto: 0,
                IdPerfil: 3, // por default el de Empleado
                IdPais: 1,
                IdEstado: 0,
                IdMunicipio: 0,
                username: '',
                password: '',
                Nombre: '',
                ApellidoPat: '',
                ApellidoMat: '',
                NombreCompleto: '',
                Genero: '',
                Correo: '',
                password_confirmation: '',
            },
            errorvalidacion: [],
            Emit: this.poBtnSave.EmitSeccion,
            ObjSucursal: {},
            ListaDePuestos:[],
        }
    },
    methods :
    {
        async Guardar()
        {
            this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;

            this.$http.put(
                'usuariossucursalPass/'+this.objUsuarioSuc.IdUsuario,
                this.objUsuarioSuc 
            ).then((res)=>{                 
                this.EjecutaConExito(res);
            }).catch(err=>{   
                this.EjecutaConError(err);
            });
        },
        EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
        Recuperar()
        {
            this.$http.get(
                "usuariossucursal/"+this.objUsuarioSuc.IdUsuario
            ).then( (res) => {
                this.objUsuarioSuc = res.data.data;
                this.objUsuarioSuc.password = '';
                this.objUsuarioSuc.password_confirmation = '';
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objUsuarioSuc = {
                IdUsuario: 0,
                IdEmpresa: this.ObjSucursal.IdEmpresa,
                IdSucursal: this.ObjSucursal.IdSucursal,
                IdPerfil: 3,
                IdPuesto: 0,
                IdPais: 1,
                IdEstado: this.ObjSucursal.IdEstado,
                IdMunicipio: this.ObjSucursal.IdMunicipio,
                username: '',
                password: '',
                Nombre: '',
                ApellidoPat: '',
                ApellidoMat: '',
                NombreCompleto: '',
                Genero: '',
                Correo: '',
                password_confirmation: '',
            };
            this.errorvalidacion = [];
        },
        ToggleShow() {

            if (this.showPassword = !this.showPassword) {                    
                this.type1 = 'text'
            } else {
                this.type1 = 'password'
            }

        },
        ToggleShowConfirm() {            
    
            if (this.showPasswordConfirm = !this.showPasswordConfirm) {                    
                this.type2 = 'text'
            } else {
                this.type2 = 'password'
            }

        }
    },
    created() {
        this.ObjSucursal = JSON.parse(sessionStorage.getItem('oSucursal'));
        
        this.poBtnSave.toast = 0;

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(item)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
                
            if(item.IdUsuario!='') 
            {
                this.objUsuarioSuc = item;
                this.Recuperar();
            }
            else
            {
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },
}
</script>