
function MenuInicio(Inicio) {
    return {
        Menu: 'Inicio',
        Icono: 'fas fa-home-lg-alt',
        Permiso: Inicio,
        Router: 'dashboardtickes',
        Parametros: {},
        TipoBoton: 0,
        SubMenus: []
    }
};

function MenuTicket(Ticket) {
    return {
        Menu: 'Mis Tickets',
        Icono: 'fas fa-ticket-alt',
        Permiso: Ticket,
        Router: 'ticket',
        Parametros: {},
        TipoBoton: 0,
        SubMenus: []
    }
};

function MenuSoporte(Soporte) {
    return {
        Menu: 'Soporte',
        Icono: 'fas fa-helmet-battle',
        Permiso: Soporte,
        Router: 'Soporte',
        Parametros: {},
        TipoBoton: 0,
        SubMenus: [
            {
                NombreSubMenu: 'Empresas',
                Icono: "",
                Permiso: true,
                Router: 'empresasoporte',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Dashboard',
                Icono: "",
                Permiso: true,
                Router: 'dashboardtickes',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Departamentos',
                Icono: "",
                Permiso: true,
                Router: 'departamentos',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Perfiles',
                Icono: "",
                Permiso: true,
                Router: 'perfiles',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Puestos',
                Icono: "",
                Permiso: true,
                Router: 'puestos',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Correo Eléctronico',
                Icono: "",
                Permiso: true,
                Router: 'correo',
                Parametros: {},
                Apartados: []
            },
            {
                NombreSubMenu: 'Mensaje Correo',
                Icono: "",
                Permiso: true,
                Router: 'mensajeCorreo',
                Parametros: {},
                Apartados: []
            },
        ]
    }
};


function ConfigMenus (usrPerfil) { 
    const Inicio  = (usrPerfil !== '' && (parseInt(usrPerfil) === 2 || parseInt(usrPerfil) === 3)) ? false : true;
    const Ticket  = (usrPerfil !== '' && parseInt(usrPerfil) === 1 ) ? false : true;
    const Soporte = (usrPerfil !== '' && (parseInt(usrPerfil) === 2 || parseInt(usrPerfil) === 3)) ? false : true;

    return [
        MenuInicio(Inicio),
        MenuTicket(Ticket),
        MenuSoporte(Soporte),
    ];
    
};


export default {
    ConfigMenus,
}
