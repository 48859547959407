<template>
<div>
    <CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
            <form id="Formestado" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="form-group form-row justify-content-between">                        
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label for="">Fecha Actual</label>
                            <input v-model="objTickets.FechaActual" disabled type="text" class="form-control" locale="es">
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 text-right">
                            <template v-if="UserEnSesion.TipoUsuario == 'Soporte'">
                                <button type="button" @click="getMessageDefault()" v-b-tooltip.hover.Top title="Agregar Mensaje" class="btn btn-sm btn-primary mt-4">
                                    <i class="far fa-envelope-open-text"></i>
                                </button>
                            </template>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">CONTACTO</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Nombre</label>
                                            <input v-model="ObjUsuario.Nombre" type="text" class="form-control" placeholder="Ingresa un Nombre" readonly>
                                            <CValidation v-if="this.errorvalidacion.Nombre" :Mensaje="'*' + errorvalidacion.Nombre[0]"/>
                                        </div>

                                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <label for="">Correo Electrónico</label>
                                            <input v-model="objTickets.Correo" type="text" class="form-control" placeholder="Ingresa un Correo Electrónico" readonly>
                                            <CValidation v-if="this.errorvalidacion.Correo" :Mensaje="'*' + errorvalidacion.Correo[0]"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">DESCRIPCIÓN</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 quillEditorcol">
                                    <div class="form-group ">
                                        <label for="">Mensaje</label>
                                        <quill-editor v-model="objTickets.Descripcion" ref="quillEditorAC" :options="editorOption" class="quillEditor"></quill-editor>
                                        <CValidation v-if="this.errorvalidacion.Descripcion" :Mensaje="'*' + errorvalidacion.Descripcion[0]"/>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-left: 0px;padding-right: 0px;">
                        <fieldset class="mb-3">
                            <legend class="legend-form">EVIDENCIA FOTOGRAFICA</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
                                    <div class="form-row mt-3 justify-content-end">
                                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 mb-2">
                                            <button type="button" @click="AgregarItem" v-if="ListaDetalle.length<3" v-b-tooltip.hover.Top title="Agregar Evidencia" class="btn-primary btn-icon-tabla2">
                                                <i class="fas fa-plus"></i><span class="tiptext"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group form-row">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4" v-for="(item3,index3) in ListaDetalle" :key="index3">
                                            <div class="box-imagen" v-if="item3.Evidencia==''">
                                                <div class="avatar-upload image-button">
                                                    <div class="avatar-preview">
                                                        <img v-if="item3.ImagenTmp == ''" src="@/assets/style/image/avatar.png" alt="">
                                                        <div :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+item3.ImagenTmp+');'" :src="(item3.ImagenTmp!='')?item3.ImagenTmp:''"></div>
                                                    </div>

                                                    <div class="icons-button">
                                                        <input type="file" :id="'fileEmp'+index3" name="file3[]" ref="file3" @change="$uploadImagePreviewArray($event,ValidElement,item3, Array('Evidencia','ImagenTmp','RutaEvid'))" accept=".png, .jpg, .jpeg">
                                                        <label :for="'fileEmp'+index3"  class="mr-1"></label>

                                                        <button type="button" @click="Eliminar(item3,index3);">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-else class="box-imagen">
                                                <div class="avatar-upload image-button">
                                                    <div class="avatar-preview">
                                                        <div :id="'imagePreviewEmp_'+index3" :style="'background-image: url('+RutaEvid+item3.Evidencia+');'" :src="RutaEvid+item3.Evidencia"></div>
                                                    </div>

                                                    <div class="icons-button">
                                                        <button type="button" class="mr-1" @click="VerPreviewImagen(item3)">
                                                            <i class="fas fa-search"></i>
                                                        </button>

                                                        <button type="button" @click="EliminarItemEvidencia(item3,index3);">
                                                            <i class="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="mb-3">
                            <legend class="legend-form">EVIDENCIA - PDF</legend>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="form-row mt-3 justify-content-end">
                                        <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 mb-2">
                                            <button type="button" @click="AgregarItemFile" v-if="ListaDetalleFile.length<3" v-b-tooltip.hover.Top title="Agregar Evidencia" class="btn-primary btn-icon-tabla2">
                                                <i class="fas fa-plus"></i><span class="tiptext"></span>
                                            </button>
                                        </div>
                                    </div>

                                    <div class="form-group form-row" v-for="(item4,index4) in ListaDetalleFile" :key="index4">
                                        <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                            <div class="input-group">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" @change="$uploadFileArrayCustom($event,ValidElementFile,item4,Array('Evidencia','NameFile'))" ref="evidenciaFile" accept="application/pdf" id="EvidenciaFile" name="EvidenciaFile[]">
                                                    <label for="EvidenciaFile" class="custom-file-label">{{item4.NameFile}}</label>
                                                </div>
                                                <div class="input-group-append ml-2">
                                                    <button type="button" @click="EliminarFile(index4);" class="btn btn-sm btn-danger">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </fieldset>
                    </div>
                
                </div>
            </form>
        </template>
    </CLoader>

    <CModal :pConfigModal="ConfigModalM" :poBtnSave="oBtnSaveM">
        <template slot="Form">
            <ListMensajes :poBtnSave="oBtnSaveM"></ListMensajes>
        </template>
    </CModal>
</div>
</template>

<script>

    import moment         from 'moment';
    import StaticComboBox from '@/helpers/StaticComboBox.js';
    import Configs        from '@/helpers/VarConfig.js';
    import ListMensajes from '@/views/modulos/tickets/components/ListMensajes.vue';

    const EmitEjecuta = "seccionMessage";

    export default {
        name:"FormTickets",
        props:['poBtnSave'],
        components:{
            moment,
            ListMensajes
        },
        data() {
            return {
                pFechaActual:       "0000-00-00",
                RutaEvid:           '', 
                Emit:               this.poBtnSave.EmitSeccion,
                ListaDetalle:       [],
                ListaDetalleFile:   [],
                SelectDepartamento: [],
                errorvalidacion:    [],
                ValidElement:       Configs.validImage2m,
                ValidElementFile:   Configs.validFile5m,
				ObjUsuario:         {},
                editorOption: {
                    placeholder: 'Escribir Mensaje',
                    theme:       'snow',
                    modules: {
                        toolbar: {
                            container: Configs.configTolbarOptions, // opciones de barra de herramientas
                        }
                    }
                },
                ConfigLoad:{
                    ShowLoader: true,
                    ClassLoad:  false,
                },
                objTickets:{
                    IdTicket:   0,
                    IdUsuario:  '',
                    IdEmpresa:  '',
                    IdSucursal: '',
                    Nombre:     '',
                    Correo:     '',
                    Estatus:    '',
                    Descripcion: '',
                    FechaActual: new Date(),
                },
                ConfigModalM:{
                    ModalTitle:  "Responder",
                    ModalNameId: 'ModalForm2',
                    EmitSeccion:  EmitEjecuta,
                    ModalSize: 'lg',
                    ShowFooter: false,
                },
                oBtnSaveM: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitEjecuta,
                },
                UserEnSesion: {},
            }
        },
        methods:  {            
            async Guardar() {
                this.errorvalidacion      = [];
                this.poBtnSave.toast      = 0; 
                this.poBtnSave.DisableBtn = true;

                let formData = new FormData();
                formData.set('IdTicket',        this.objTickets.IdTicket);
                formData.set('IdUsuario',       this.ObjUsuario.IdUsuario);
                formData.set('IdEmpresa',       this.ObjUsuario.IdEmpresa);
                formData.set('IdSucursal',      this.ObjUsuario.IdSucursal);
                formData.set('Nombre',          this.ObjUsuario.Nombre);
                formData.set('Correo',          this.objTickets.Correo);
                formData.set('Descripcion',     this.objTickets.Descripcion);
                formData.set('Estatus',         this.objTickets.Estatus);

                let files3 = document.getElementsByName('file3[]');

                for(var i=0;i<this.ListaDetalle.length;i++)
                {
                    let file = (files3[i])?files3[i].files[0]:'';
                    // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA IMAGEN COMO CAMPO OBLIGATORIO
                    if(this.ListaDetalle[i].Evidencia =='' && file===undefined && this.ListaDetalle[i].IdTicketEvidencia == 0)
                    {
                        this.$toast.warning('La fotografía '+(i+1)+' de la evidencia debe ser agregada');
                        return false;
                    }
                    else
                    {
                        if(file!=undefined){
                            formData.append('FileEvidencia[]',file);
                        }
                    }
                }

                let fileinput = document.getElementsByName('EvidenciaFile[]');

                for(var i=0;i<this.ListaDetalleFile.length;i++)
                {
                    let file = (fileinput[i])?fileinput[i].files[0]:'';
                    // PRIMERO VALIDAMOS QUE LA PRIMERA VEZ EXISTA UN ARCHIVO COMO CAMPO OBLIGATORIO
                    if(this.ListaDetalleFile[i].Evidencia =='' && file===undefined && this.ListaDetalleFile[i].IdTicketEvidencia == 0)
                    {
                        this.$toast.warning('La archivo '+(i+1)+' de la evidencia pdf debe ser agregado');
                        return false;
                    }
                    else
                    {
                        if(file!=undefined){
                            formData.append('FileEvidenciaArch[]',file);
                        }
                    }
                }

                this.$http.post('responderticket', formData,{
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((res)=>{                 
                    this.EjecutaConExito(res);
                }).catch(err=>{   
                    this.EjecutaConError(err);
                });
            },
            EjecutaConExito(res) {
                this.poBtnSave.DisableBtn = false;  
                this.poBtnSave.toast      = 1;
                this.bus.$emit('CloseModal_'+this.Emit);
                this.bus.$emit('Recuperar');
            },
            EjecutaConError(err) {
                this.poBtnSave.DisableBtn = false;
                
                if(err.response.data.errors){
                    this.errorvalidacion = err.response.data.errors;
                    this.poBtnSave.toast = 2;
                } else {
                    this.$toast.error(err.response.data.message);
                }
            },
            Limpiar() {
                this.objTickets = {
                    IdTicket:        0,
                    IdUsuario:       '',
                    IdEmpresa:       '',
                    IdSucursal:      '',
                    Correo:          '',
                    Nombre:          this.ObjUsuario.Nombre,
                    Folio:           '',
                    Asunto:          '',
                    TipoPrioridad:   '',
                    Telefono:        '',
                    TipoTicket:      '',
                    Descripcion:     '',
                    Recepcion:       '',
                    Cotizacion:      '',
                    OrdenTrabajo:    '',
                    OrdenCompra:     '',
                    CalibracionExt:  '',
                    Requisicion:     '',
                    Otro:            '',
                    MotivoSolicitud: '',
                    Departamento:    '',
                    Estatus:         '',
                    FechaActual:     moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
                };
                this.errorvalidacion = [];
            },
            AgregarItem() {
                this.ListaDetalle.push({
                    IdTicketEvidencia: 0,
                    IdTicket: 0,
                    Evidencia: '',
                    TipoEvidencia: 'Imagen',
                    Descripcion: '',
                    ImagenTmp: '',
                    NameFile: this.ValidElement.NameFile
                });
            },
            Eliminar(index){
                this.$swal(Configs.configEliminarItem).then((result) => {

                    if(result.value) {
                        this.$swal(Configs.configEliminarConfirm);
                        this.ListaDetalle.splice(index, 1);
                    }
                });
            },
            AgregarItemFile() {
                this.ListaDetalleFile.push({
                    IdTicketEvidencia: 0,
                    IdTicket: 0,
                    Evidencia: '',
                    TipoEvidencia: 'Archivo',
                    Descripcion: '',
                    NameFile: this.ValidElementFile.NameFile
                });
            },
            EliminarFile(index){
                this.$swal(Configs.configEliminarItem).then((result) => {

                    if(result.value) {
                        this.$swal(Configs.configEliminarConfirm);
                        this.ListaDetalleFile.splice(index, 1);
                    }
                });
            },
            getMessageDefault(){
                this.bus.$emit('NewModal_'+EmitEjecuta,this.UserEnSesion);
            },
        },
        created() {
            this.poBtnSave.toast = 0;
			//this.Session();
            this.bus.$off('pAsignarMensaje');

            this.bus.$off('Recovery_'+this.Emit);
            this.bus.$on('Recovery_'+this.Emit,(pObjTicket)=> {
                
                this.ConfigLoad.ShowLoader = true;    
                this.poBtnSave.DisableBtn  = false;

                this.bus.$off('Save_'+this.Emit);
                this.bus.$on('Save_'+this.Emit,() => {
                    this.Guardar();
                });

                this.Limpiar();
                       
                this.objTickets.IdTicket = pObjTicket.IdTicket;
                this.objTickets.Correo = pObjTicket.Correo;
                this.ObjUsuario = pObjTicket.Usuario;
                this.ConfigLoad.ShowLoader = false;
                this.UserEnSesion = JSON.parse(sessionStorage.getItem('user'));
            });
        },
        mounted(){
            this.bus.$on('pAsignarMensaje',(obj)=>
            {
                this.objTickets.Descripcion = obj.Texto;
            });
        }
    }
</script>