const EstadosCiviles = [
    {id: 1, label:'Soltera (o)'} ,
    {id: 2, label:'Casada (o)'} ,
    {id: 3, label:'Divorciada (o)'} ,
    {id: 4, label:'Union Libre'} ,
    {id: 5, label:'Viuda (o)'} ,
    {id: 6, label:'Concubinato'} ,
];

const TipoFamiliar = [
    {id: 1, label:'Pareja'} ,
    {id: 2, label:'Fam. Emergencia'} ,
    {id: 3, label:'Otro'} ,
];

const Genero = [
    {id: 1, label:'Femenino'} ,
    {id: 2, label:'Masculino'} ,
];

const EstatusEquipo = [
    {id: 1, label:'Funcionando'} ,
    {id: 2, label:'Observacion'},
    {id: 3, label:'Falla'},
    {id: 4, label:'Inactivo'},

];

const TipoLicenciaEquipo = [
    {id: 1, label:'Microsoft Office'} ,
    {id: 2, label:'Antivirus'},
    {id: 3, label:'Programa Adobe'},
    {id: 4, label:'Otro'},

];

const TipoTicket = [
    {id: 1, label:'Soporte Proceso'} ,
    {id: 2, label:'Asistencia Tecnica'} ,
];

const TipoPrioridad = [
    {id: 1, label:'Baja'} ,
    {id: 2, label:'Media'} ,
    {id: 3, label:'Alta'} ,
];

const MotivoSolicitud = [
    {id: 1, label:'Error Humano'} ,
    {id: 2, label:'Fallas del sistema'} ,
];

const Departamento = [
    {id: 1, label:'Laboratorio'},
    {id: 2, label:'Ventas'},
    {id: 3, label:'Recepción'},
    {id: 4, label:'Compras'},
    {id: 5, label:'Finanzas'},
    {id: 6, label:'Otro'},
];


export default {
    EstadosCiviles,
    TipoFamiliar,
    Genero,
    EstatusEquipo,
    TipoLicenciaEquipo,
    TipoTicket,
    TipoPrioridad,
    MotivoSolicitud,
    Departamento
} ;
