<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormEquipolicencia" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
							
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="TipoLicencia">Tipo de Licencia</label>
									<select v-model="objEquipolicencia.TipoLicencia" class="form-control" id="TipoLicencia">
										<option value="0">Seleccione una opción</option>
										<option v-for="(item, index) in ListCombo1" :key="index" :value="item.label">{{item.label}}</option>
									</select>
									<CValidation v-if="this.errorvalidacion.TipoLicencia" :Mensaje="'*'+errorvalidacion.TipoLicencia[0]"/>
								</div>
							</div>
					
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="NoLicencia">No de Licencia</label>
									<input type="text" v-model="objEquipolicencia.NoLicencia" class="form-control" id="NoLicencia" placeholder="Ingresar NoLicencia" />
									<CValidation v-if="this.errorvalidacion.NoLicencia" :Mensaje="'*'+errorvalidacion.NoLicencia[0]"/>
								</div>
							</div>
		
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="UsuarioLicencia">Usuario Licencia</label>
									<input type="text" v-model="objEquipolicencia.UsuarioLicencia" class="form-control" id="UsuarioLicencia" placeholder="Ingresar UsuarioLicencia" />
									<CValidation v-if="this.errorvalidacion.UsuarioLicencia" :Mensaje="'*'+errorvalidacion.UsuarioLicencia[0]"/>
								</div>
							</div>
		
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Password">Password</label>
									<input type="text" v-model="objEquipolicencia.Password" class="form-control" id="Password" placeholder="Ingresar Password" />
									<CValidation v-if="this.errorvalidacion.Password" :Mensaje="'*'+errorvalidacion.Password[0]"/>
								</div>
							</div>
		
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="FechaActivacion">Fecha de Activacion</label>
									<v-date-picker v-model="objEquipolicencia.FechaActivacion" :masks="masks" :popover="{visibility: 'focus' }" locale="es">
										<template v-slot="{ inputValue, inputEvents }">
											<input class="form-control" :value="inputValue" v-on="inputEvents" readonly/>
										</template>
									</v-date-picker>
									<CValidation v-if="this.errorvalidacion.FechaActivacion" :Mensaje="'*'+errorvalidacion.FechaActivacion[0]"/>
								</div>
							</div>
		
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="FechaVencimiento">Fecha de Vencimiento</label>
									<v-date-picker v-model="objEquipolicencia.FechaVencimiento" :masks="masks" :popover="{visibility: 'focus' }"  locale="es">
										<template v-slot="{ inputValue, inputEvents }">
											<input class="form-control" :value="inputValue" v-on="inputEvents" readonly/>
										</template>
									</v-date-picker>
									<CValidation v-if="this.errorvalidacion.FechaVencimiento" :Mensaje="'*'+errorvalidacion.FechaVencimiento[0]"/>
								</div>
							</div>
		
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Observaciones">Observaciones</label>
									<textarea v-model="objEquipolicencia.Observaciones" class="form-control" id="Observaciones" placeholder="Ingresar Observaciones" rows="3"></textarea>
									<CValidation v-if="this.errorvalidacion.Observaciones" :Mensaje="'*'+errorvalidacion.Observaciones[0]"/>
								</div>
							</div>
								
							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>
import CLoader from "../../../components/CLoader.vue";
import CValidation from "../../../components/CValidation.vue";
import StaticComboBox from "../../../helpers/StaticComboBox";

export default {
    name: 'FormEquipolicencia',
    props: ['poBtnSave','poEquipo'],
    components:{
		CLoader,
        CValidation
    },
    data() {
        return {
			RutaFile:        '',
            Img:             null,
            Emit:            this.poBtnSave.EmitSeccion,
			errorvalidacion: [],
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
			ListCombo1: StaticComboBox.TipoLicenciaEquipo,
			
			localEquipo:{},

			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },

            objEquipolicencia: {
				IdLicenciaEquipo: 0,
				IdEquipo: '',
				TipoLicencia: 0,
				NoLicencia: '',
				UsuarioLicencia: '',
				Password: '',
				FechaActivacion: '',
				FechaVencimiento: '',
				Observaciones: '',
				
            },
           
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;
			this.objEquipolicencia.IdEquipo = this.localEquipo.IdEquipo;

			if(this.objEquipolicencia.IdLicenciaEquipo == 0) {
				await this.$http.post(
					'equiposlicencias',this.objEquipolicencia
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'equiposlicencias/'+this.objEquipolicencia.IdLicenciaEquipo,
					this.objEquipolicencia
				).then((res)=>{                 
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
                this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "equiposlicencias/"+this.objEquipolicencia.IdLicenciaEquipo
            ).then((res) => {
                this.objEquipolicencia = res.data.data;

				// en caso de que necesite fecha
				let formatedDate1 = this.objEquipolicencia.FechaActivacion.replace(/-/g,'\/');
                this.objEquipolicencia.FechaActivacion = new Date(formatedDate1);

				let formatedDate2 = this.objEquipolicencia.FechaVencimiento.replace(/-/g,'\/');
                this.objEquipolicencia.FechaVencimiento = new Date(formatedDate2);

            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objEquipolicencia = {
				IdLicenciaEquipo: 0,
				IdEquipo: '',
				TipoLicencia: 0,
				NoLicencia: '',
				UsuarioLicencia: '',
				Password: '',
				FechaActivacion: '',
				FechaVencimiento: '',
				Observaciones: '',
				
            }
            this.errorvalidacion = [];
        },
		
    },
    created() {
		this.poBtnSave.toast = 0;

		if(this.poEquipo != undefined){
            this.localEquipo = this.poEquipo;
        }

        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
                
            if (Id!='') 
            {
                this.objEquipolicencia.IdLicenciaEquipo = Id;
                this.Recuperar();
            }
            else
            {	
				this.objEquipolicencia.IdEquipo = this.localEquipo.IdEquipo;
                this.ConfigLoad.ShowLoader = false;
            }
        });
    }
}
</script>
