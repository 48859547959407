<template>
    <div :class="mode">

        <NavLinks :mode="mode" @toggle="toggle"></NavLinks>

        <section class="mt-2" :mode="mode">
            <div class="container-fluid">
                <transition name="fade" mode="out-in">
                    <router-view :mode="mode" :key="$route.fullPath"/>
                </transition>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name:'Template',
    props:[""],
    components:{ },
    data() {
        return {
            mode:           'light',
            SessionStorage: {},
        }
    },
    methods: {
        getJquery() { // INICIO DE USO ESTRICTO
            (function($) { "use strict";
                $("ul.dropdown-menu [data-toggle='dropdown']").on("click", function(event) {
                    event.preventDefault();
                    event.stopPropagation();

                    if (!$(this).next().hasClass('show')) {
                        $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
                    }

                    $(this).siblings().toggleClass("show");

                    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                        $('.dropdown-submenu .show').removeClass("show");
                    });
                });
            })(jQuery);
		},
        Getdatauser() { // DATOS DE SESSIÓN
            this.SessionStorage = JSON.parse(sessionStorage.getItem('user'));
        },
        keyPress (e) {
            if (e.key === 't') {
                this.toggle()
            }
        },
        toggle () {
            if (this.mode === "dark") {
                this.mode = "light"
            } else {
                this.mode = "dark"
            }
        }
    },
    created() {
		this.Getdatauser();
        window.addEventListener('keyup', this.keyPress)

    },
    mounted() {
        this.getJquery();
    },
}
</script>
