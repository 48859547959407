/******************NOTAS******************/

// 1.- SEPARAR LAS VISTAS DE LOS CATALOGOS CON LOS DE LOS MODULOS.
// 2.- LOS NOMBRES DE LOS COMPONENTES SIEMPRE IRAN CON MAYUSCULAS.
//     EL PATH Y EL COMPONENT COMIENZAN CON MAYUSCULA CADA INICIO DE PALABRA.
//     EL NAME COMIENZA CON MINUSCULA.
// 3.- SI REQUIERE PROPS, ACTIVARLO CON UN TRUE.

/**************FIN DE NOTAS**************/

// -------------------------------------------------------------------------- IMPORTACIONES PRINCIPALES -----------------------------------------------------------
import Vue       from "vue";
import VueRouter from "vue-router";
import Store     from "../store/index";

// -------------------------------------------------------------------------- IMPORTACIONES GENERALES --------------------------------------------------------------
import Template          from "@/views/template/Template.vue";
import Login             from "@/views/security/Login.vue";
import RecuperarPassword from "@/views/security/RecuperarPassword.vue";
import RecoveryAccount   from "@/views/security/RestablecerPassword.vue";
import Anomalias         from "@/views/anomalias/List.vue";
import FormAnomalias     from "@/views/anomalias/Form.vue";

// -------------------------------------------------------------------------- IMPORTACIONES HIJAS ------------------------------------------------------------------
import RouterImportInicio      from "./RutasInicio.js";
import RouterImportMenuSoporte from "./RutasSoporte";
import RouterImportTicket      from "./RutasTicket.js";

const ArrayRutas = RouterImportInicio.VarRutasInicio.concat(
    RouterImportMenuSoporte.VarRutasSoporte,
    RouterImportTicket.VarRutasTicket,
);

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "login",
    component: Login,
}, 
{
    path: "/anomalias",
    name: "anomalias",
    component: Anomalias,
    props: true 
},
{
    path: "/viewanomalia",
    name: "formanomalias",
    component: FormAnomalias,
    props: true 
},

{
    // ------------------------------------------------ RUTAS GENERALES ------------------------------------------------
    path: "/Inicio",
    name: "template",
    component: Template,
    props: true,
    meta: {
        requiresAuth: true,
    },
    children: ArrayRutas,
}, {
    path: '/RecuperarPassword',
    name: 'recuperarpassword',
    component: RecuperarPassword,
    props: true,
}, {
    path: '/recoveryaccount/:key/:id/:num',
    name: 'recoveryaccount',
    component: RecoveryAccount,
}, ];

const router = new VueRouter({
    mode: 'history',  // Cambiamos la visualización del # en la url de la vista.
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (Store.getters.isLoggedIn) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default router;
