<template>
    <div>
        <section class="container-fluid padding-right-0 padding-left-0">
            <div class="row mt-5">

                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="card card-03">
                        <div class="card-header card-header-info card-header-icon">
                            <div class="card-icon">
                                <i class="fad fa-exclamation-triangle fa-fw-m fa-2x"></i>
                            </div>
                            <p class="card-category">Tickets Pendientes</p>
                            <h3 class="card-title">{{countTickets.Pendientes}}</h3>
                        </div>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="fas fa-search-plus fa-lg text-info"></i>
                                <a @click="viewTicketsEstatus('Pendiente')" style="cursor: pointer;" class="stretched-link text-info">Ver más...</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="card card-03">
                        <div class="card-header card-header-warning card-header-icon">
                            <div class="card-icon">
                                <i class="fad fa-laptop-medical fa-fw-m fa-2x"></i>
                            </div>
                            <p class="card-category">Tickets en proceso</p>
                            <h3 class="card-title">{{countTickets.Procesos}}</h3>
                        </div>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="fas fa-search-plus fa-lg text-warning"></i>
                                <a @click="viewTicketsEstatus('Proceso')" style="cursor: pointer;" class="stretched-link text-warning">Ver más...</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="card card-03">
                        <div class="card-header card-header-success card-header-icon">
                            <div class="card-icon">
                                <i class="fad fa-shield-check fa-fw-m fa-2x"></i>
                            </div>
                            <p class="card-category">Tickets Cerrados</p>
                            <h3 class="card-title">{{countTickets.Cerrados}}</h3>
                        </div>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="fas fa-search-plus fa-lg text-success"></i>
                                <a @click="viewTicketsEstatus('Cerrado')" style="cursor: pointer;" class="stretched-link text-success">Ver más...</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                    <div class="card card-03">
                        <div class="card-header card-header-daguer card-header-icon">
                            <div class="card-icon">
                                <i class="fad fa-radiation-alt fa-fw-m fa-2x"></i>
                            </div>
                            <p class="card-category">Tickets de Urgentes</p>
                            <h3 class="card-title">{{countTickets.Urgentes}}</h3>
                        </div>
                        <div class="card-footer">
                            <div class="stats">
                                <i class="fas fa-search-plus fa-lg text-danger"></i>
                                <a @click="viewTicketsEstatus('Urgente')" style="cursor: pointer;" class="stretched-link text-danger">Ver más...</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CList @FiltrarC="listTickets" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="Filtros">
                <div class="form-group mx-sm-3 mr-1">
                    <label for="IdEmpresa" class="mr-1">Empresas</label>
                    <select id="IdEmpresa" class="form-control form-select" v-model="Filtro.IdEmpresa" @change="getCombainFilter()" style="width: 86px;">
                        <option value="0">Todas</option>
                        <option v-for="(lista,index) in listEmpresas" :key="index" :value="lista.id">{{lista.label}}</option>
                    </select>
                </div>
                <div class="form-group mx-sm-3 mr-1">
                    <label for="IdSucursal" class="mr-1">Sucursales</label>
                    <select id="IdSucursal" class="form-control form-select" v-model="Filtro.IdSucursal" @change="listTickets()"  style="width: 160px;">
                        <option value="0">Todas</option>
                        <option v-for="(lista,index) in listSucursales" :key="index" :value="lista.id">{{lista.label}}</option>
                    </select>
                </div>
                <button class="btn btn-outline-light mt-1" @click="limpiarFiltros">Limpiar Filtros</button>
            </template>
            <template slot="header">
                <th class="text-center"># Folio</th>
                <th class="text-center">Fecha</th>
                <th>Empresa</th>
                <th>Usuario</th>
                <th>Asunto</th>
                <th class="text-center">Prioridad</th>
                <th class="text-center">Estatus</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in listaTickets" :key="index" >
                    <td class="text-center" style="width: 6%"><b>{{lista.Folio}}</b></td>
                    <td class="text-center" style="width: 12%">{{$getCleanDate(lista.created_at,true)}}</td>
                    <td style="width: 10%"><CAvatar :fullname="lista.empresa.NombreComercial" :size="25" :radius="50"/> {{lista.empresa.NombreComercial }}</td>
                    <td style="width: 15%"> {{limitCharacters(lista.usuario.NombreCompleto,25) }}</td>
                    <td>{{limitCharacters(lista.Asunto,80) }}</td>
                    <td class="text-center" style="width: 10%">
                        <template v-if="lista.TipoPrioridad == 3"> <b-badge pill variant="danger" style="width: 80px;">Alta</b-badge> </template>
                        <template v-else-if="lista.TipoPrioridad == 2"> <b-badge pill variant="warning" style="width: 80px;">Media</b-badge> </template>
                        <template v-else-if="lista.TipoPrioridad == 1"> <b-badge  pill variant="info" style="width: 80px;">Baja</b-badge> </template>
                    </td>
                    <td class="text-center" style="width: 10%">
                        <span v-if="lista.Estatus == 'Pendiente'" class="badge badge-secondary" style="width: 80px;">Pendiente</span>
                        <span v-else-if="lista.Estatus == 'Proceso'" class="badge badge-primary" style="width: 80px;">En Proceso</span>
                        <span v-else-if="lista.Estatus == 'Atendido'" class="badge badge-warning" style="width: 80px;">Atendido</span>
                        <span v-else-if="lista.Estatus == 'Cerrado'" class="badge badge-danger" style="width: 80px;">Cerrado</span>
                    </td>
                    <td class="text-center" style="width: 10%">
                        <CBtnAccion :pShowBtnEdit="false" :pShowBtnDelete="false" :pIsModal="false" :pId="lista.IdTicket" :pEmitSeccion="ConfigList.EmitSeccion" :pGoRoute="ConfigList.GoRoute">
                            <template slot="btnaccion">
                                <button type="button" v-b-tooltip.hover.Top title="Ir al Ticket" class="btn btn-primary btn-icon mr-1" @click="viewTickets(lista.IdTicket)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistros :pContIF="listaTickets.length" :pColspan="8"></CSinRegistros>
            </template>
        </CList>


    </div>
</template>

<script>
import CList from "../../../components/CList";
import CBtnAccion from "../../../components/CBtnAccion";
import CSinRegistros from "../../../components/CSinRegistros";
import moment from "moment-timezone";
const EmitEjecuta = 'listDashTickets';
export default {
    name: "ViewPanelTickets",
    props:[],
    components:{
        CList,
        CBtnAccion,
        CSinRegistros
    },
    data(){
        return {
            ConfigList:{
                Title: 'Listado de Tickets',
                IsModal: true,
                ShowLoader: true,
                BtnReturnShow: false,
                BtnNewShow:false,
                GoRoute: 'viewticket',
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                EstatusTicket:'',
                Prioridad:'',
                IdEmpresa: '0',
                IdSucursal:'0',
                Placeholder: 'Folio, Usuario, Asunto',
            },
            segurity: {},
            countTickets: {
                Pendientes: 0,
                Procesos: 0,
                Cerrados: 0,
                Urgentes: 0
            },
            listaTickets:[],
            listEmpresas: [],
            listSucursales: [],
            //IntervalTime: null,
        }
    },
    methods:{

        async listTickets()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get('dashboardlist', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,
                    Estatus: this.Filtro.EstatusTicket,
                    Prioridad: this.Filtro.Prioridad,
                    IdEmpresa: this.Filtro.IdEmpresa,
                    IdSucursal: this.Filtro.IdSucursal
                }
            }).then( (res) => {
                this.listaTickets       = res.data.data.data;
                this.Filtro.Pagina      = res.data.data.current_page;
                this.Filtro.TotalItem   = res.data.data.total;
            }).finally(()=>{
                this.getCounters();
                this.ConfigList.ShowLoader = false;
            });
        },

        async getCounters()
        {
            this.ConfigList.ShowLoader = false;

            await this.$http.get('dashboardtickets',{
                params:{
                    IdEmpresa: this.Filtro.IdEmpresa,
                    IdSucursal: this.Filtro.IdSucursal
                }
            }).then( (res) => {
                this.countTickets     = res.data.data.countTickets;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        async getEmpresas()
        {
            this.listEmpresas = [];
            await this.$http.get('empresas',{
                params:{
                    isSimple: 1
                }
            }).then( (res) => {
                this.listEmpresas     = res.data.data;
            }).finally(()=>{
              // this.getSucursales();
            });
        },

        async getSucursales()
        {
            this.listSucursales = [];
            if(parseInt(this.Filtro.IdEmpresa) > 0) {
                await this.$http.get('sucursales', {
                    params: {
                        isSimple: 1,
                        IdEmpresa: this.Filtro.IdEmpresa
                    }
                }).then((res) => {
                    this.listSucursales = res.data.data.map(item => ({
                        id: item.IdSucursal,
                        label: item.Nombre
                    }));

                }).finally(() => {

                });
            }
        },

        getCombainFilter(){
            this.listTickets();
            this.getSucursales();
        },

        limitCharacters(text,limit = 50){
            if(text !== ''){
                let points = text.length > limit ? '...' : '';
                return text.substr(0,100)+points;
            }else {
                return ''
            }

        },

        viewTicketsEstatus(tp){
            this.Filtro.EstatusTicket   = '';
            this.Filtro.Prioridad       = '';

            if(tp === 'Urgente'){
                this.Filtro.EstatusTicket   = 'Pendiente';
                this.Filtro.Prioridad       = 3;
            }else {
                this.Filtro.EstatusTicket = tp;
            }

            this.listTickets();
        },

        limpiarFiltros(){
            this.Filtro.EstatusTicket = '';
            this.Filtro.Prioridad     = '';
            this.Filtro.Nombre        = '';
            this.Filtro.IdEmpresa     = '0';
            this.Filtro.IdSucursal    = '0';
            this.listTickets();
        },

        viewTickets(Id) {
            this.$router.push({
                name:this.ConfigList.GoRoute,
                params:{
                    Id:Id,
                    Origen:'Dash'
                }
            })
        },

        timer() {
            this.IntervalTime = setInterval(
                function() {
                    this.chageDataTimer("");
                }.bind(this),
                300000
            );
        },

        chageDataTimer(){
            this.limpiarFiltros();
        }
    },

    created() {
        this.timer();
        this.listTickets();
        this.getEmpresas();
    }
}
</script>

<style scoped>

</style>
