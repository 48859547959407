<template>
	<CLoader :pConfigLoad="ConfigLoad">
        <template slot="BodyFormLoad">
			<form id="FormBitacoraMantenimiento" class="form-horizontal" method="post" autocomplete="off" onSubmit="return false">
				<div class="container-fluid">
					<div class="row">
						<div class="col-12 col-sm-12 col-md-12 col-lg-12">
			
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="IdSolicitoMant">Solicita Mantenimiento</label>
									<select v-model="objBitacoraMantenimiento.IdSolicitoMant" class="form-control" id="IdSolicitoMant">
										<option value="0">Seleccione una opción</option>
										<option v-for="(item, index) in ListCombo1" :key="index" :value="item.IdUsuario">{{item.NombreCompleto}}</option>
									</select>
									<CValidation v-if="this.errorvalidacion.IdSolicitoMant" :Mensaje="'*'+errorvalidacion.IdSolicitoMant[0]"/>
								</div>
							</div>
					
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="FechaMantenimiento">Fecha del Mantenimiento</label>
									<v-date-picker v-model="objBitacoraMantenimiento.FechaMantenimiento" :masks="masks" :popover="{visibility: 'focus' }" locale="es">
										<template v-slot="{ inputValue, inputEvents }">
											<input class="form-control" :value="inputValue" v-on="inputEvents" readonly/>
										</template>
									</v-date-picker>
									<CValidation v-if="this.errorvalidacion.FechaMantenimiento" :Mensaje="'*'+errorvalidacion.FechaMantenimiento[0]"/>
								</div>
							</div>
			
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="SituacionFalla">Situación de Falla</label>
									<textarea v-model="objBitacoraMantenimiento.SituacionFalla" class="form-control" id="SituacionFalla" placeholder="Ingresar SituacionFalla" rows="3"></textarea>
									<CValidation v-if="this.errorvalidacion.SituacionFalla" :Mensaje="'*'+errorvalidacion.SituacionFalla[0]"/>
								</div>
							</div>
						
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Diagnostico">Diagnostico</label>
									<textarea v-model="objBitacoraMantenimiento.Diagnostico" class="form-control" id="Diagnostico" placeholder="Ingresar Diagnostico" rows="3"></textarea>
									<CValidation v-if="this.errorvalidacion.Diagnostico" :Mensaje="'*'+errorvalidacion.Diagnostico[0]"/>
								</div>
							</div>
						
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="ReporteTrabajo">Reporte de Trabajo</label>
									<textarea v-model="objBitacoraMantenimiento.ReporteTrabajo" class="form-control" id="ReporteTrabajo" placeholder="Ingresar ReporteTrabajo" rows="3"></textarea>
									<CValidation v-if="this.errorvalidacion.ReporteTrabajo" :Mensaje="'*'+errorvalidacion.ReporteTrabajo[0]"/>
								</div>
							</div>
						
							<div class="form-group form-row">
								<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<label for="Observaciones">Observaciones Finales</label>
									<textarea v-model="objBitacoraMantenimiento.Observaciones" class="form-control" id="Observaciones" placeholder="Ingresar Observaciones" rows="3"></textarea>
									<CValidation v-if="this.errorvalidacion.Observaciones" :Mensaje="'*'+errorvalidacion.Observaciones[0]"/>
								</div>
							</div>
											
							<br>
						</div><!--fin col-12-->
					</div>
				</div>
			</form>
		</template>
	</CLoader>
</template>

<script>
import CLoader from "../../../components/CLoader.vue";
import CValidation from "../../../components/CValidation.vue";

export default {
    name: 'FormBitacoraMantenimiento',
    props: ['poBtnSave','poEquipo'],
    components:{
		CLoader,
        CValidation
    },
    data() {
        return {
			RutaFile:        '',
            Img:             null,
            Emit:            this.poBtnSave.EmitSeccion,
			errorvalidacion: [],
			masks:{ input: 'YYYY-MM-DD'}, // quitar sino lleva fecha
			ListCombo1: [],
			localEquipo:{},

			ConfigLoad:{
                ShowLoader: true,
                ClassLoad: false,
            },
            objBitacoraMantenimiento: {
				IdBitacoraMantenimiento: 0,
				IdEquipo: 0,
				IdSolicitoMant: 0,
				FechaMantenimiento: '',
				SituacionFalla: '',
				Diagnostico: '',
				ReporteTrabajo: '',
				Observaciones: '',
				
            },
        
			
        }
    },
    methods :
    {
       	async Guardar()
        {
			this.errorvalidacion = [];
            this.poBtnSave.toast = 0; 
            this.poBtnSave.DisableBtn = true;
			this.objBitacoraMantenimiento.IdEquipo = this.localEquipo.IdEquipo;

			if(this.objBitacoraMantenimiento.IdBitacoraMantenimiento == 0) {
				await this.$http.post(
					'bitacorasmantenimiento',this.objBitacoraMantenimiento
				).then((res)=>{
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			} else {
				await this.$http.put(
					'bitacorasmantenimiento/'+this.objBitacoraMantenimiento.IdBitacoraMantenimiento,
					this.objBitacoraMantenimiento
				).then((res)=>{                 
					this.EjecutaConExito(res);
				}).catch(err=>{   
					this.EjecutaConError(err);
				});
			}
        },
		EjecutaConExito(res)
        {
            this.poBtnSave.DisableBtn = false;  
            this.poBtnSave.toast = 1;
            this.bus.$emit('CloseModal_'+this.Emit);
            this.bus.$emit('List_'+this.Emit);
        },
        EjecutaConError(err)
        {
            this.poBtnSave.DisableBtn = false;
            
            if(err.response.data.errors){
				this.errorvalidacion = err.response.data.errors;
                this.poBtnSave.toast = 2;
            }
            else{
                this.$toast.error(err.response.data.message);
            }
        },
		Recuperar()
        {
            this.$http.get(
                "bitacorasmantenimiento/"+this.objBitacoraMantenimiento.IdBitacoraMantenimiento
            ).then((res) => {
                this.objBitacoraMantenimiento = res.data.data;

				// en caso de que necesite fecha
				let formatedDate1 = this.objBitacoraMantenimiento.FechaMantenimiento.replace(/-/g,'\/');
                this.objBitacoraMantenimiento.FechaMantenimiento = new Date(formatedDate1);

				
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });
        },
        Limpiar()
        {
            this.objBitacoraMantenimiento = {
				IdBitacoraMantenimiento: 0,
				IdEquipo: 0,
				IdSolicitoMant: 0,
				FechaMantenimiento: '',
				SituacionFalla: '',
				Diagnostico: '',
				ReporteTrabajo: '',
				Observaciones: '',
				
            }
            this.errorvalidacion = [];
        },
		get_arregloCombo1()
		{
			this.$http.get("usuariossucursal",{
				params:{
					IdSucursal:this.localEquipo.IdSucursal,
					Entrada: 100,
				}
			}).then((res) => {
				this.ListCombo1 = res.data.data.data;
			});
		},
    },
    created() {
		this.poBtnSave.toast = 0;

		if(this.poEquipo != undefined){
            this.localEquipo = this.poEquipo;
        }

		this.get_arregloCombo1();


        this.bus.$off('Recovery_'+this.Emit);
        this.bus.$on('Recovery_'+this.Emit,(Id)=>
        {
            this.ConfigLoad.ShowLoader = true;    
            this.poBtnSave.DisableBtn = false;

            this.bus.$off('Save_'+this.Emit);
            this.bus.$on('Save_'+this.Emit,()=>
            {
                this.Guardar();
            });
            this.Limpiar();
                
            if (Id!='') 
            {
                this.objBitacoraMantenimiento.IdBitacoraMantenimiento = Id;
                this.Recuperar();
            }
            else
            {	this.objBitacoraMantenimiento.IdEquipo = this.localEquipo.IdEquipo;
                this.ConfigLoad.ShowLoader = false;
            }
        });
    },

	mounted() {
        
    }
}
</script>
