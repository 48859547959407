<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="header">
                <th class="td-sm"></th>
                <th>#</th>
                <th>Empresa</th>
                <th>Razón Social</th>
                <th>Telefono</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{(index+1)}}</td>
                    <td><CAvatar :fullname="lista.NombreComercial" :size="25" :radius="50"/> {{lista.NombreComercial }}</td>
                    <td>{{lista.RazonSocial }}</td>
                    <td>{{lista.Telefono }}</td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="false" :pId="lista.IdEmpresa" :pEmitSeccion="ConfigList.EmitSeccion" :pGoRoute="ConfigList.GoRoute">
                            <template slot="btnaccion">

                            </template>
                        </CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="10"></CSinRegistro>
            </template>
        </CList>
    </div>
</template>

<script>
''
import Form    from '../empresasoporte/Form';
import Configs from '@/helpers/VarConfig.js';
import CModal from "../../../components/CModal";
import CList from "../../../components/CList";
import CBtnAccion from "../../../components/CBtnAccion";
import CSinRegistro from "../../../components/CSinRegistros"
import CAvatar from "../../../components/CAvatar";

const EmitEjecuta = 'seccionEmpresaSoporte';

export default {
    name: 'ListaEmpresasSoporte',
    components: {
        Form,
        CModal,
        CList,
        CSinRegistro,
        CBtnAccion,
        CAvatar
    },
    data() {
        return {
            ConfigList:{
                Title:         'Listado Empresas',
                IsModal:       false,
                ShowLoader:    true,
                BtnReturnShow: false,
                GoRoute: 'empresa',
                EmitSeccion:   EmitEjecuta,

            },
            Filtro:{
                Nombre:      '',
                Pagina:      1,
                Entrada:     10,
                TotalItem:   0,
                Placeholder: 'Buscar..',
            },
            ConfigModal:{
                ModalTitle:  "Formulario Empresas",
                ModalSize:   'xl',
                EmitSeccion:  EmitEjecuta,
            },
            oBtnSave: {
                toast:       0,
                IsModal:     true,
                ShowBtnSave: true,
                ShowBtnCancel: true,
                EmitSeccion: EmitEjecuta,
            },
            segurity:       {},
            obj:            {},
            ListaArrayRows: [],
            ListaHeader:    [],
        }
    },
    methods: {
        async Lista(){
            this.ConfigList.ShowLoader = true;

            await this.$http.get('empresas', {
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada:     this.Filtro.Entrada,
                    Pag:         this.Filtro.Pagina,
                }
            }).then( (res) => {
                this.ListaArrayRows     = res.data.data.data;
                this.Filtro.Pagina      = res.data.data.current_page;
                this.Filtro.TotalItem   = res.data.data.total;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        Eliminar(Id){
            this.$swal(Configs.configEliminar).then((result) => {
                if(result.value)
                {
                    this.$http.delete('empresas/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                }
            });
        },

        goToSucursales(lista){
            this.$router.push({name: 'sucursalsoporte',params:{pObjEmpresa:lista}});
        }
    },
    created()
    {
        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
        sessionStorage.setItem('oEmpresa','');
    },
    mounted()
    {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });
    },
    // beforeDestroy() {
    //     this.bus.$off('List');
    // },
}
</script>

