<template>
    <div>
        <CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
            <template slot="header">
                <th class="td-sm"></th>
                <th>#</th>
                <th>Nombre</th>
                <th>Perfil</th>
                <th>Correo</th>
                <th>Telefono</th>
                <th class="text-center">Acciones</th>
            </template>

            <template slot="body">
                <tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
                    <td>{{(index+1)}}</td>
                    <td>{{lista.NombreCompleto}}</td>
                    <td>{{lista.perfil.Nombre}}</td>
                    <td>{{lista.Correo}}</td>
                    <td>{{lista.Telefono}}</td>
                    <td class="text-center">
                        <CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdUsuario" :pEmitSeccion="ConfigList.EmitSeccion">
							<template slot="btnaccion">
                                <button type="button" v-b-tooltip.hover.Top title="Contraseña" @click="CambiarPass(lista);" class="btn btn-warning btn-icon ml-1">
                                    <i class="fa fa-key"></i>
                                </button>
                            </template>
						</CBtnAccion>
                    </td>
                </tr>
                <CSinRegistro :pContIF="ListaArrayRows.length" :pColspan="7"></CSinRegistro>
            </template>
        </CList> 

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave"></Form>
			</template>
		</CModal>

        <CModal :pConfigModal="ConfigModal2" :poBtnSave="oBtnSave2">
			<template slot="Form">
				<FormPass :poBtnSave="oBtnSave2"></FormPass>
			</template>
		</CModal>
    </div>
</template>

<script>

    import Form       from '@/views/catalogos/usuarioSucursal/Form.vue';
    import FormPass   from '@/views/catalogos/usuarioSucursal/FormPass.vue';
    import Configs    from '@/helpers/VarConfig.js';
    const EmitEjecuta = 'seccionUsersSuc';
    const EmitAsigna  = 'seccionPassword';

    export default {
        name: 'ListaUsuarios',
        props:['pObjSucursal'],
        components: {
            Form,
            FormPass,
        },
        data() {
            return {
                ConfigList:{
                    Title: 'Listado de Usuarios',
                    TitleFirst: 'Empresa',
                    IsModal: true,
                    ShowLoader: true,
                    BtnReturnShow: true,
                    EmitSeccion: EmitEjecuta,
                },
                Filtro:{
                    Nombre: '',
                    Pagina: 1,
                    Entrada: 10,
                    TotalItem: 0,
                    Placeholder: 'Buscar..',
                },
                ConfigModal:{
                    ModalTitle:  "Formulario Usuarios",
                    ModalNameId: 'ModalForm',
                    EmitSeccion:  EmitEjecuta,
                    ModalSize: 'lg'
                },
                oBtnSave: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitEjecuta,
                },
                ConfigModal2:{
                    ModalTitle:  "Cambiar Contraseña",
                    ModalNameId: 'ModalForm2',
                    EmitSeccion:  EmitAsigna,
                    ModalSize: 'md',
                },
                oBtnSave2: {
                    toast: 0,
                    IsModal: true,
                    DisableBtn: false,
                    EmitSeccion: EmitAsigna,
                },
                segurity:       {},
                obj:            {},
                ListaArrayRows: [],
                ListaHeader:    [],
                ObjSucursal:      {}
            }
        },
        methods: {
            async Lista()
            {
                this.ConfigList.ShowLoader = true;

                await this.$http.get('usuariossucursal', {
                    params:{
                        TxtBusqueda: this.Filtro.Nombre,
                        Entrada:     this.Filtro.Entrada,
                        Pag:         this.Filtro.Pagina,
                        IdSucursal:  this.ObjSucursal.IdSucursal,
                    }
                }).then( (res) => {
                    this.ListaArrayRows   = res.data.data.data;
                    this.Filtro.Pagina    = res.data.data.current_page;
                    this.Filtro.TotalItem = res.data.data.total;
                }).finally(()=>{
                    this.ConfigList.ShowLoader = false;
                });
            },
            Eliminar(Id)
            {
                this.$swal(Configs.configEliminar).then((result) => {
                    if(result.value)
                    {
                        this.$http.delete('usuariossucursal/'+Id)
                        .then( (res) => {
                            this.$swal(Configs.configEliminarConfirm);
                            this.Lista();
                        })
                        .catch( err => {
                            this.$toast.error(err.response.data.message);
                        });
                    }
                });
            },
            Regresar()
            {
                this.$router.push({name:'empresa',params:{Id:this.ObjSucursal.IdEmpresa}});
            },
            CambiarPass(item)
            {
                this.bus.$emit('NewModal_'+EmitAsigna,item);
            }
        },
        created()
        {
            if(this.pObjSucursal != undefined){
                sessionStorage.setItem('oSucursal',JSON.stringify(this.pObjSucursal));
            }

            this.ObjSucursal = JSON.parse(sessionStorage.getItem('oSucursal'));
            this.ConfigList.Title = this.ConfigList.Title+': '+this.ObjSucursal.Nombre;

            this.bus.$off('Delete_'+EmitEjecuta);
            this.bus.$off('List_'+EmitEjecuta);
            this.bus.$off('EmitReturn');
        },
        mounted()
        {
            this.Lista();
            this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
            {
                this.Eliminar(Id);
            });

            this.bus.$on('List_'+EmitEjecuta,()=>
            {
                this.Lista();
            });

            this.bus.$on('EmitReturn',()=>
            {
                this.Regresar();
            });
        },
    }

</script>
