<template>
	<div>
		<CList @FiltrarC="Lista" :pConfigList="ConfigList" :pFiltro="Filtro" :segurity="segurity">
			<template slot="header">
                <th class="td-sm"></th>
                <th>#</th>

                <th>Solicito</th>
                <th>Fecha</th>
                <th>Situacion</th>

                <th class="text-center">Acciones</th>
            </template>

			<template slot="body">
				<tr v-for="(lista,index) in ListaArrayRows" :key="index" >
                    <td class="td-sm"></td>
					<td>{{ (index+1) }}</td>

                    <td v-if="lista.solicitante !== null">{{lista.solicitante.NombreCompleto}}</td>
                    <td v-else>-----</td>

                    <td>{{lista.FechaMantenimiento}}</td>
                    <td>{{lista.SituacionFalla}}</td>

					<td>
						<CBtnAccion :pShowBtnEdit="true" :pShowBtnDelete="true" :pIsModal="true" :pId="lista.IdBitacoraMantenimiento" :pEmitSeccion="ConfigList.EmitSeccion">
							<template slot="btnaccion">
                            </template>
						</CBtnAccion>
					</td>
				</tr>
                <CSinRegistros :pContIF="ListaArrayRows.length" :pColspan="6"/>
			</template>
		</CList>

		<CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
			<template slot="Form">
				<Form :poBtnSave="oBtnSave" :poEquipo="pObjEquipo"></Form>
			</template>
		</CModal>
	</div>
</template>

<script>

import moment from 'moment';
import CList from "../../../components/CList.vue";
import CSinRegistros from "../../../components/CSinRegistros.vue";
import CBtnAccion from "../../../components/CBtnAccion.vue";
import CModal from "../../../components/CModal.vue";
import Form from '@/views/catalogos/bitacoramantenimiento/Form.vue';
import Configs from '@/helpers/VarConfig.js';
const EmitEjecuta = 'seccionBitacoraMantenimiento';

export default {
    name: 'ListaBitacoraMantenimiento',
    props:['pObjEquipo','pObjSucursal'],
    components: {
        Form,
        CModal,
        CSinRegistros,
        CBtnAccion,
        CList
    },
    data() {
        return {
            ConfigList:{
                Title: 'Mantenimientos',
                IsModal: true,
                /*ShowYear: true,*/
                ShowLoader: true,
                BtnReturnShow: true,
                TitleFirst:'Equipos',
                EmitSeccion: EmitEjecuta,
            },
            Filtro:{
                Nombre: '',
                Pagina: 1,
                Entrada: 10,
                TotalItem: 0,
                Placeholder: 'Buscar..',
            },
            ConfigModal:{
                ModalTitle:  "Formulario Mantenimiento",
                ModalNameId: 'ModalForm',
                EmitSeccion:  EmitEjecuta,
                ModalSize: 'lg'
            },
            oBtnSave: {
                Toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },
            segurity: {},
            obj: {},
            ListaArrayRows: [],
            ObjEquipo: {},
        }
    },
    methods: {
		async Lista()
        {
            this.ConfigList.ShowLoader = true;

            await this.$http.get("bitacorasmantenimiento",{
                params:{
                    TxtBusqueda: this.Filtro.Nombre,
                    Entrada: this.Filtro.Entrada,
                    Pag: this.Filtro.Pagina,
                    IdEquipo: this.ObjEquipo.IdEquipo,
                }
            }).then((res) => {
                this.ListaArrayRows   = res.data.data.data;
                this.Filtro.Pagina    = res.data.data.current_page;
                this.Filtro.TotalItem = res.data.data.total;
            }).finally(()=>{
                this.ConfigList.ShowLoader = false;
            });
        },

        Eliminar(Id)
        {
            this.$swal(Configs.configEliminar).then((result) =>
            {
                if(result.value)
                {
                    this.$http.delete('bitacorasmantenimiento/'+Id
                    ).then((res) => {
                        this.$swal(Configs.configEliminarConfirm);
                        this.Lista();
                    }).catch(err => {
                        this.$toast.error(err.response.data.message);
                    });
                }
            });
        },

        Regresar(){
            this.$router.push({name:'equiposmantenimiento',params:{}});
        },
    },
    created()
    {
        if(this.pObjEquipo != undefined){
            sessionStorage.setItem('objEquipo',JSON.stringify(this.pObjEquipo));
        }

        this.ObjEquipo = JSON.parse(sessionStorage.getItem('objEquipo'));
        this.ConfigList.Title = this.ConfigList.Title+': '+this.ObjEquipo.Marca +' '+this.ObjEquipo.NoSerie;

        this.bus.$off('Delete_'+EmitEjecuta);
        this.bus.$off('List_'+EmitEjecuta);
        this.bus.$off('EmitReturn');
    },
    mounted()
    {
        this.Lista();
        this.bus.$on('Delete_'+EmitEjecuta,(Id)=>
        {
            this.Eliminar(Id);
        });

        this.bus.$on('List_'+EmitEjecuta,()=>
        {
            this.Lista();
        });

        this.bus.$on('EmitReturn',()=>
        {
            this.Regresar();
        });
    }
}
</script>
